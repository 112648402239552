<div class="component">
    <div class="panel">

        <h2>
            {{"Mounting System" | translate}}
        </h2>

        <div class="hint"
             *ngIf="segments.length == 0">
            {{"You don't have any roof segments yet." | translate}}
        </div>

        <ng-container *ngIf="segments.length > 0">

            <h3>{{"General" | translate}}</h3>

            <mat-form-field>
                <mat-label>{{"Roof Segment" | translate}}</mat-label>
                <mat-select [(ngModel)]="activeSegment"
                            (ngModelChange)="onSegmentChange()">
                    <mat-option *ngFor="let segment of segments"
                                [value]="segment">
                        {{segment.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{"Roof Type" | translate}}</mat-label>
                <mat-select [(ngModel)]="project.mountingSystem.roofType"
                            (ngModelChange)="onOptionsChange()">
                    <mat-option *ngFor="let roofType of roofTypes"
                                [value]="roofType.id">
                        {{roofType.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <websolar-number-input *ngIf="project.mountingSystem.roofType == 'With Parapets'"
                                   [label]="'Parapet Height' | translate"
                                   [(value)]="project.mountingSystem.parapetHeight"
                                   (valueChange)="onOptionsChange()"
                                   type="distance"></websolar-number-input>

            <mat-form-field>
                <mat-label>{{"Mounting System" | translate}}</mat-label>
                <mat-select [(ngModel)]="project.mountingSystem.type"
                            [disabled]="!manufacturers.length"
                            (ngModelChange)="onTypeOptionsChange()">
                    <mat-option *ngFor="let manufacturer of manufacturers"
                                [value]="manufacturer">
                        {{manufacturer}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{"Mounting System Module" | translate}}</mat-label>
                <mat-select [(ngModel)]="project.mountingSystem.mountingSystemId"
                            [disabled]="!manufacturers.length"
                            (ngModelChange)="onOptionsChange()">
                    <mat-option *ngFor="let mounting of mountingSystems"
                                [value]="mounting._id">
                        {{mounting.model}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <mat-form-field>
                <mat-label>{{"Structure Design Life" | translate}}</mat-label>
                <mat-select [(ngModel)]="activeSegment.mountingSystem.designLife"
                            (ngModelChange)="onOptionsChange()">
                    <mat-option *ngFor="let v of designLifes"
                                [value]="v">
                        <span>{{v}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <h2>{{"MECHANICAL PROPERTIES" | translate}}</h2>
            <websolar-number-input [label]="'Module Weight' | translate"
                                   [(value)]="project.mountingSystem.moduleWeight"
                                   (valueChange)="onOptionsChange()"
                                   type="weight"></websolar-number-input>

            <ng-container *ngIf="activeSegment.module">
                <websolar-number-input [label]="'Module Length' | translate"
                                       [(value)]="activeSegment.module.width"
                                       [disabled]="true"
                                       type="distance"></websolar-number-input>
                <websolar-number-input [label]="'Module Width' | translate"
                                       [(value)]="activeSegment.module.height"
                                       [disabled]="true"
                                       type="distance"></websolar-number-input>
                <websolar-number-input [label]="'Module Thickness' | translate"
                                       [(value)]="activeSegment.module.depth"
                                       [disabled]="true"
                                       type="distance"></websolar-number-input>
            </ng-container>


            <h2>{{"SITE PROPERTIES" | translate}}</h2>

            <app-mounting-europe *ngIf="region == 'eu'"
                                 [project]="project"
                                 [segment]="activeSegment"
                                 [events]="eventsAsObservable"
                                 [isCaclulating]="isCaclulating"
                                 [calcRes]="calcRes"
                                 (onChange)="onOptionsChange()"></app-mounting-europe>


            <app-mounting-cn *ngIf="region == 'cn'"
                             [project]="project"
                             [segment]="activeSegment"
                             [events]="eventsAsObservable"
                             [isCaclulating]="isCaclulating"
                             [calcRes]="calcRes"
                             (onChange)="onOptionsChange()"></app-mounting-cn>


            <div class="calc-btn-wrapper">

                <button mat-stroked-button
                        color="primary"
                        [disabled]="!hasResults || isCaclulating"
                        (click)="showCalcTable()">
                    {{"Show Calculations" | translate}}
                </button>

                <div *ngIf="isCaclulating">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>

            </div>

            <h3>{{"Roof Segment" | translate}}</h3>

            <websolar-number-input *ngIf="activeSegment.mountingSystem.orientation == 'horz'"
                                   [label]="'Hook Vertical Spacing' | translate"
                                   [(value)]="activeSegment.mountingSystem.railSpan"
                                   (valueChange)="onOptionsChange()"></websolar-number-input>

            <websolar-number-input *ngIf="activeSegment.mountingSystem.orientation == 'vert'"
                                   [label]="'Hook Vertical Spacing' | translate"
                                   [(value)]="activeSegment.mountingSystem.hookSpan"
                                   (valueChange)="onOptionsChange()"></websolar-number-input>


            <websolar-number-input [label]="'Rafter Span' | translate"
                                   [(value)]="activeSegment.mountingSystem.rafterSpan"
                                   (valueChange)="onOptionsChange()"
                                   type="distance"></websolar-number-input>


            <websolar-number-input [label]="'Max Mount Spaicing' | translate"
                                   [(value)]="activeSegment.mountingSystem.maxMountSpacing"
                                   (valueChange)="onOptionsChange()"
                                   type="distance"></websolar-number-input>


            <div>
                {{"Adjust Rafters Positions:" | translate}}
            </div>
            <mat-slider [(ngModel)]="activeSegment.mountingSystem.rafterOffset"
                        (ngModelChange)="onOptionsChange()"
                        color="primary"
                        step="0.01"
                        min="0"
                        max="0.5"
                        aria-label="units"></mat-slider>

            <websolar-number-input [label]="'Max Cantilever length' | translate"
                                   [(value)]="activeSegment.mountingSystem.maxCantileverLength"
                                   [disabled]="true"></websolar-number-input>

            <mat-form-field>
                <mat-label>{{"Beam span number" | translate}}</mat-label>
                <input matInput
                       [(ngModel)]="activeSegment.mountingSystem.beemSpanNumber"
                       [disabled]="true">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{"Number of sub rail per module" | translate}}</mat-label>
                <mat-select [(ngModel)]="activeSegment.mountingSystem.numberOfSubRails"
                            (ngModelChange)="onOptionsChange()">
                    <mat-option *ngFor="let v of numberOfRailes"
                                [value]="v">
                        <span>{{v}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>


        </ng-container>


    </div>

    <div class="design">

        <div class="design-layout"
             *ngIf="activeSegment">
            <websolar-mounting-layout [project]="project"
                                      [segment]="activeSegment"
                                      [objects]="objects"
                                      [calcResult]="calcRes"
                                      [options]="renderingOptions"
                                      (statistic)="onLayoutStatistic($event)"></websolar-mounting-layout>
        </div>
    </div>

</div>
