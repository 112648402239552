import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotifyService, Solar, SolarInstance, WebSolarOptimizerService } from '@websolar/ng-websolar';

@Component({
    selector: 'app-inverter-edit-fields',
    templateUrl: './inverter-edit-fields.component.html',
    styleUrls: ['./inverter-edit-fields.component.scss']
})
export class InverterEditFieldsComponent {

    public inverterObj: Solar.ObjectInverter;

    public optimizer?: Solar.Optimizer | null;

    public isLoading = false;

    private _instance: SolarInstance;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            instance: SolarInstance,
            inverter: Solar.ObjectInverter
        },
        private _notify: NotifyService,
        private _dialogRef: MatDialogRef<InverterEditFieldsComponent>,
        private _wsOptimizerService: WebSolarOptimizerService
    ) {
        this._instance = data.instance;
        this.inverterObj = data.inverter;
        this.optimizer = this.inverterObj.optimizer;
    }



    /**
     * Handles the change event of the optimizer.
     * 
     * @param optimizer The optimizer object.
     */
    public onOptimizerChange(inverter: Solar.ObjectInverter, optimizer?: Solar.Optimizer) {
        try {
            if (!inverter) {
                return;
            }
            if (!optimizer) {

                this.optimizer = null;

                const inverters = this._instance.getObjects({ types: ["inverter"] }) as Solar.ObjectInverter[];
                if (!inverters.find(i => i.optimizer)) {
                    // if no optimizer selected then remove all optimezers in scene as well
                    this._instance.removeObjects({ types: ["optimizer"] });
                }
                return;
            }

            this.optimizer = optimizer;
        }
        catch (err) {
            // reset the optimizer
            this.optimizer = {} as Solar.Optimizer;
            setTimeout(() => {
                this.optimizer = null;
            }, 1)

            this._notify.error(err);
        }
    }


    public close() {
        this._dialogRef.close(false);
    }

    public confirm() {
        // set optimizer
        this.inverterObj.optimizer = this.optimizer;

        this._dialogRef.close(true);
    }
}
