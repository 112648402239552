<div *ngIf="permitScope"
     class="component">

    <div class="package-card">

        <div class="title">
            {{"CONDUCTOR / CONDUIT SCHEDULE" | translate}}
        </div>

        <div class="table-wrapper">

            <table mat-table
                   [dataSource]="permitScope.content.electricalCalc"
                   class="table">
                <ng-container matColumnDef="tag">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"Tag" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record"> {{record.id}} </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"CONDUCTOR TYPE" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record"> {{record.conductorType}} </td>
                </ng-container>

                <ng-container matColumnDef="number">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"NUMBER OF CONDUCTORS" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record"> {{record.numberConductors}} </td>
                </ng-container>

                <ng-container matColumnDef="cable-type">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"CONDUIT/CABLE TYPE" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record">
                        {{record.conduitType}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="size">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"CONDUIT SIZE" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record">
                        {{record.conduitSize}}
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="['tag', 'type', 'number', 'cable-type', 'size']; sticky: true"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: ['tag', 'type', 'number', 'cable-type', 'size'];"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row"
                    *matNoDataRow>
                    <td class="mat-cell"
                        colspan="5">{{"No data matching" | translate}}</td>
                </tr>
            </table>
        </div>

    </div>
</div>
