import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DialogService } from '../../../services/dialog.service';
import { SolarInstance, Solar, NotifyService, WebSolarWiringService, WebSolarEventsService } from '@websolar/ng-websolar';

@Component({
    selector: 'app-main-service-panel-list',
    templateUrl: './main-service-panel-list.component.html',
    styleUrls: ['./main-service-panel-list.component.scss']
})
export class MainServicePanelListComponent implements OnChanges {
    @Input() instance!: SolarInstance;

    @Input() project!: Solar.Project;

    @Output() toolActivated = new EventEmitter<{ type: Solar.ToolType, params: unknown }>();

    public items: Solar.ObjectMainServicePanel[] = [];

    public itemState: { [key: string]: { expanded: boolean, item: Solar.ObjectMainServicePanel } } = {};

    public connectionTypes = [
        { id: "load-side-tap", name: "Load Side Tap" },
        { id: "line-side-tap", name: "Line Side Tap" }
    ];

    constructor(
        private _dialogService: DialogService,
        private _notify: NotifyService,
        private _wiringService: WebSolarWiringService,
        private _eventService: WebSolarEventsService
    ) {
        this._eventService.events.subscribe((opt) => {
            if (opt.name == "tool_completed" ||
                opt.name == "object_deleted" ||
                opt.name == "undo" ||
                opt.name == "redo") {
                this.updateList();
            }
            else if (opt.name == "object_picked") {
                this.onObjectPicked(opt.params as Solar.Object);
            }
        })
    }

    public ngOnChanges(changes: SimpleChanges): void {
        try {
            if (this.instance && this.project) {
                // sync the config
                this._wiringService.sync(this.instance, this.project);
            }

            this.updateList();
        }
        catch (err) {
            console.error();
        }
    }

    private onObjectPicked(object: Solar.Object) {
        try {
            if (object.type != "main_service_panel") {
                return;
            }

            // collapse other segments
            for (const key of Object.keys(this.itemState)) {
                if (key != object.id) {
                    this.itemState[key].expanded = false;
                }
            }

            this.itemState[object.id].expanded = true;
        }
        catch (err) {
            console.error(err);
        }
    }

    public updateList() {
        try {
            if (!this.instance) {
                return;
            }
            const prevItems = this.items;

            this.items = this.instance.getObjects({ types: ["main_service_panel"] }) as Solar.ObjectMainServicePanel[];

            for (const item of this.items) {
                this.initState(item);
            }

            if (prevItems && prevItems.length && prevItems.length < this.items.length) {
                // probably new item added
                const last = this.items[this.items.length - 1];
                this.itemState[last.id].expanded = true;
            }

            if (this.items.length == 1) {
                // expand it
                this.itemState[this.items[0].id].expanded = true;
            }
        }
        catch (err) {
            console.error();
        }
    }

    public onNew() {
        this.toolActivated.emit({ type: "main_service_panel", params: null });
    }

    public toggle(segment: Solar.ObjectMainServicePanel) {
        this.itemState[segment.id].expanded = !this.itemState[segment.id].expanded;
    }

    private initState(obj: Solar.ObjectMainServicePanel) {
        this.itemState[obj.id] = {
            expanded: false,
            item: obj
        };
    }

    public onItemChange(item: Solar.ObjectMainServicePanel) {

        // remove children
        this.instance.removeObjects({
            ownerId: item.id
        });

        // delete item as well
        this.instance.removeObjects({
            id: item.id
        });

        // create new one
        const sceneObject = this.instance.createObject(item);
        if (!sceneObject) {
            return;
        }
        this.instance.scene.add(sceneObject);

        // update electrical config
        this.updateElectricalConfig();
    }

    private updateElectricalConfig() {
        if (this.project && this.project.electrical.stringsConfig) {
            this._wiringService.sync(this.instance, this.project);
        }
    }

    public connectionChanged(panel: Solar.ObjectMainServicePanel) {
        if (panel.connectionType == "line-side-tap") {
            panel.pvBreaker = 0;
        }
        else {
            panel.pvBreaker = 40;
        }

        // update electrical config
        this.updateElectricalConfig();
    }

    public onToolActivated(opt: Solar.ToolOptions) {
        this.toolActivated.emit(opt)
    }

    public async onDelete(item: Solar.ObjectMainServicePanel) {
        try {
            const confirm = await this._dialogService.confirm({
                title: `Delete the main service panel`,
                text: `Are you sure you want to delete this main service panel?`,
                okBtn: "Delete"
            })
            if (!confirm) {
                return;
            }
            // remove children
            this.instance.removeObjects({
                ownerId: item.id
            });

            // delete keepout as well
            this.instance.removeObjects({
                id: item.id
            });

            // remove from the list
            this.items = this.items.filter(k => k != item);

            // syn wiring config
            this._wiringService.sync(this.instance, this.project);
        }
        catch (err) {
            this._notify.error(err);
        }
    }



    public getErrorMessage(panel: Solar.ObjectMainServicePanel): string {
        if (typeof panel.pvBreaker != "number" ||
            panel.pvBreaker < 0) {
            return "Solar breaker rating is incorrect"
        }

        if (typeof panel.mainBreaker != "number" ||
            panel.mainBreaker <= 0) {
            return "Main breaker rating is incorrect"
        }

        if (typeof panel.raiting != "number" ||
            panel.raiting <= 0) {
            return "Bus Bar Size is incorrect"
        }

        // check 120 rule
        const max = panel.raiting * 1.2;
        if ((panel.mainBreaker + panel.pvBreaker) > max) {
            return `The combined current draw from the main breaker and solar breaker exceeds the maximum limit`;
        }

        return "";

    }
}
