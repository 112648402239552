import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SolarInstance, Solar, NotifyService, WebSolarEventsService } from '@websolar/ng-websolar';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
    selector: 'app-electric-pole-list',
    templateUrl: './electric-pole-list.component.html',
    styleUrls: ['./electric-pole-list.component.scss']
})
export class ElectricPoleListComponent implements OnChanges {

    @Input() instance!: SolarInstance;

    @Input() project?: Solar.Project;

    @Output() toolActivated = new EventEmitter<{ type: Solar.ToolType, params: unknown }>();

    public poles: Solar.ObjectElectricalPole[] = [];

    public itemState: { [key: string]: { expanded: boolean, item: Solar.ObjectElectricalPole } } = {};

    constructor(
        private _notify: NotifyService,
        private _dialogService: DialogService,
        private _eventService: WebSolarEventsService
    ) {
        // subscribe to events
        this._eventService.eventsAsObservable.subscribe((opt) => {
            if (opt.name == "tool_completed" ||
                opt.name == "object_deleted" ||
                opt.name == "undo" ||
                opt.name == "redo") {
                this.updateList();
            }
            else if (opt.name == "object_picked") {
                this.onObjectPicked(opt.params as Solar.Object);
            }
        })
    }

    public ngOnChanges(): void {
        try {
            this.updateList();
        }
        catch (err) {
            console.error();
        }
    }

    private onObjectPicked(object: Solar.Object) {
        try {
            if (object.type != "electrical_pole") {
                return;
            }

            // collapse other segments
            for (const key of Object.keys(this.itemState)) {
                if (key != object.id) {
                    this.itemState[key].expanded = false;
                }
            }

            this.itemState[object.id].expanded = true;
        }
        catch (err) {
            console.error(err);
        }
    }

    private updateList() {
        try {
            if (!this.instance) {
                return;
            }
            const prevItems = this.poles;

            this.poles = this.instance.getObjects({ types: ["electrical_pole"] }) as Solar.ObjectElectricalPole[];

            for (const item of this.poles) {
                this.initObject(item);
            }

            if (prevItems && prevItems.length && prevItems.length < this.poles.length) {
                // probably new item added
                const last = this.poles[this.poles.length - 1];
                this.itemState[last.id].expanded = true;
            }

            if (this.poles.length == 1) {
                // expand it
                this.itemState[this.poles[0].id].expanded = true;
            }
        }
        catch (err) {
            console.error();
        }
    }

    public onNew() {
        this.toolActivated.emit({ type: "electrical_pole", params: null });

        this.resetSimulationStatus();
    }

    public toggle(segment: Solar.ObjectElectricalPole) {
        this.itemState[segment.id].expanded = !this.itemState[segment.id].expanded;
    }

    private initObject(meter: Solar.ObjectElectricalPole) {
        this.itemState[meter.id] = {
            expanded: false,
            item: meter
        };
    }

    public onItemChange(item: Solar.ObjectElectricalPole) {
        // update the pole on the drawing
        const newObj = this.instance.createObject(item);
        if (!newObj) {
            return;
        }

        // delete the previous item
        this.instance.removeObjects({
            id: item.id
        });

        this.instance.scene.add(newObj);

        this.resetSimulationStatus();
    }

    private resetSimulationStatus() {
        if (this.project) {
            // reset simulation statys
            this.project.simulationStatus = "";
        }
    }

    public onToolActivated(opt: Solar.ToolOptions) {
        this.toolActivated.emit(opt)
    }

    public async onDelete(item: Solar.ObjectElectricalPole) {
        try {
            const confirm = await this._dialogService.confirm({
                title: `Delete electrical pole`,
                text: `Are you sure you want to delete this electrical pole?`,
                okBtn: "Delete"
            })
            if (!confirm) {
                return;
            }
            // remove children
            this.instance.removeObjects({
                ownerId: item.id
            });

            // delete item as well
            this.instance.removeObjects({
                id: item.id
            });

            // remove from the list
            this.poles = this.poles.filter(k => k != item);

            this.resetSimulationStatus();
        }
        catch (err) {
            this._notify.error(err);
        }
    }





}
