import { Component, Input, OnInit } from '@angular/core';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-permit-conductor-schedule',
    templateUrl: './permit-conductor-schedule.component.html',
    styleUrls: ['./permit-conductor-schedule.component.scss']
})
export class PermitConductorScheduleComponent  {

    @Input() permitScope!: AIKO.PermitScope;

}
