<mat-form-field (click)="openSearch($event)">
    <mat-label>{{"Inverter" | translate}}</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input matInput
           [ngModel]="inverterName"
           readonly
           type="search"
           [placeholder]="'Click to search inverter' | translate">

    <button matSuffix
            mat-icon-button
            color="primary">
        <mat-icon fontSet="material-icons-outlined">folder_open</mat-icon>
    </button>

</mat-form-field>
