<div class="component">
    <div class="new-button">
        <button mat-stroked-button
                color="primary"
                (click)="onNew()">
            <mat-icon>add</mat-icon>
            {{'New Electric Pole' | translate}}
        </button>
    </div>

    <div class="list-item"
         *ngFor="let pole of poles">
        <div class="line highlight">
            <div class="title">
                <span (click)="toggle(pole)"
                      class="title-text">
                    {{pole.name || ("Electric Pole" | translate)}}
                </span>

                <button class="menu-btn"
                        mat-icon-button
                        (click)="onDelete(pole)"
                        color="primary">
                    <mat-icon>delete</mat-icon>
                </button>

            </div>
            <div class="info"
                 (click)="toggle(pole)">

                <div *ngIf="itemState[pole.id]"
                     class="info-data">
                    <mat-icon inline="true">height</mat-icon>
                    <span>{{itemState[pole.id].item.height | distance }}</span>

                    <app-icon [src]="'icons/angle.svg' | assetUrl"></app-icon>
                    <span>{{itemState[pole.id].item.rotation?.z| rotation }}</span>
                </div>


                <div class="expander">
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="itemState[pole.id]?.expanded">
                        expand_less
                    </mat-icon>
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="!itemState[pole.id]?.expanded">
                        expand_more
                    </mat-icon>
                </div>
            </div>
        </div>

        <div *ngIf="itemState[pole.id]?.expanded"
             class="item-content">
            <mat-form-field>
                <mat-label>{{'Description' | translate}}</mat-label>
                <input matInput
                       [placeholder]="'Enter the name' | translate"
                       [(ngModel)]="pole.name">
            </mat-form-field>


            <websolar-number-input [label]="'Height' | translate"
                                   [(value)]="pole.height"
                                   (valueChange)="onItemChange(pole)"
                                   size="large"
                                   [placeholder]="'Enter the height' | translate"></websolar-number-input>

            <websolar-number-input *ngIf="pole.rotation"
                                   [label]="'Rotation angle' | translate"
                                   [(value)]="pole.rotation.z"
                                   (valueChange)="onItemChange(pole)"
                                   type="angle_rad"
                                   [matTooltip]="'Wire deflection angle, east-west direction is 0°' | translate"></websolar-number-input>

        </div>
    </div>

    <div class="hint"
         *ngIf="poles.length == 0">
        {{"You don't have any electric poles yet" | translate}}
    </div>

</div>
