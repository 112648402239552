import { Component, Input, OnInit } from '@angular/core';
import { Solar } from '@websolar/ng-websolar';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-permit-attachment-plan',
    templateUrl: './permit-attachment-plan.component.html',
    styleUrls: ['./permit-attachment-plan.component.scss']
})
export class PermitAttachmentPlanComponent {

    @Input() permitScope!: AIKO.PermitScope;

    @Input() segment!: Solar.ObjectRooftopSegment;

    public renderingOptions: Solar.MountingRenderSettings = {
        /**
         * 300mm is allowed maximum
         */
        maxCantiliver: 0.3,
        /**
         * 100 mm is rail extension
         * The extra part is used to place the briquette.
         */
        railEndExtension: 0.1
    }

}
