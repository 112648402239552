<div class="component">

    <div class="title">
        {{ "Shading" | translate}}
    </div>

    <div class="date-picker-wrapper">
        <mat-form-field>
            <mat-label>{{"Choose a date" | translate}}</mat-label>
            <input matInput
                   [matDatepicker]="picker"
                   [disabled]="isCalculating"
                   [(ngModel)]="date"
                   (ngModelChange)="dateChanged()">
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="time-picker-wrapper">
        <app-time-picker [(time)]="startTime"
                         (timeChange)="startTimeChanged()"
                         [disabled]="isCalculating"></app-time-picker>
        <app-time-picker [(time)]="endTime"
                         (timeChange)="endTimeChanged()"
                         [disabled]="isCalculating"></app-time-picker>
    </div>

    <div class="shadow-outline">
        <button mat-flat-button
                color="primary"
                [disabled]="isCalculating"
                (click)="toggleShadowOutline()">
            <span *ngIf="!isOutlineVisible">
                {{"Shadow outline" | translate}}
            </span>
            <span *ngIf="isOutlineVisible">
                {{"Hide outline" | translate}}
            </span>            
        </button>
        <div *ngIf="isCalculating">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>

    <div>
        <div class="sub-title">
            {{"Shadow animation" | translate}}
        </div>
        <div>
            <button *ngIf="!isAnimating"
                    [disabled]="isCalculating"
                    mat-flat-button
                    color="primary"
                    (click)="startAnimation()">
                <mat-icon>play_arrow</mat-icon>
            </button>
            <button *ngIf="isAnimating"
                    [disabled]="isCalculating"
                    mat-flat-button
                    color="primary"
                    (click)="stopAnimation()">
                <mat-icon>pause</mat-icon>
            </button>
        </div>
    </div>
</div>
