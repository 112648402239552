import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { InverterEditWindowComponent } from '../inverter-edit-window/inverter-edit-window.component';
import { TranslateService } from '@ngx-translate/core';
import { Solar, NotifyService, WebSolarAuthService, WebSolarInverterService } from '@websolar/ng-websolar';
import { CloneTool } from 'src/app/core/clone.tool';

@Component({
    selector: 'app-inverter-picker-window',
    templateUrl: './inverter-picker-window.component.html',
    styleUrls: ['./inverter-picker-window.component.scss']
})
export class InverterPickerWindowComponent implements AfterViewInit {

    @ViewChild("paginator") paginator!: MatPaginator;

    public searchText = "";

    public displayedColumns = ['manufacturer', 'model', 'description', 'max_power', 'max_voltage', 'min_voltage', "copy"];

    public pageOptions = [10, 20, 30];

    public resultsLength = 0;

    public isLoading = true;

    public inverters: Solar.Inverter[] = [];

    private _timer: unknown;

    private _systemSize: number = 0;

    private _manufacturerFilter: string[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            systemSize: number,
            manufacturer: string[]
        },
        private _dialogRef: MatDialogRef<InverterPickerWindowComponent>,
        private _inverterService: WebSolarInverterService,
        private _notify: NotifyService,
        private _auth: WebSolarAuthService,
        private _matDialog: MatDialog,
        private _paginator: MatPaginatorIntl,
        private _translate: TranslateService
    ) {
        this._systemSize = data.systemSize || 0;
        this._manufacturerFilter = data.manufacturer || [];

        if (window.innerWidth < 900) {
            this.displayedColumns = ['manufacturer', 'model', 'max_power'];
        }

        // update paginator lang
        this._paginator.itemsPerPageLabel = this._translate.instant("Items per page");
    }

    ngAfterViewInit(): void {
        this.reload();
    }

    public async reload() {
        try {
            this.isLoading = true;
            this.inverters = [];

            this.resultsLength = await this._inverterService.count({
                search: this.searchText,
                manufacturer: this._manufacturerFilter,
                // exclude big inverters
                maxSize: this.getMaxInverterSize()
            })

            await this.loadPage();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public onSearch() {
        if (this._timer) {
            clearTimeout(this._timer as number)
        }

        this._timer = setTimeout(() => {
            this.paginator.pageIndex = 0;
            this.reload();
        }, 300);
    }


    public async loadPage() {
        try {
            this.isLoading = true;
            this.inverters = [];

            const skip = this.paginator.pageIndex * this.paginator.pageSize;

            this.inverters = await this._inverterService.find({
                search: this.searchText,
                manufacturer: this._manufacturerFilter,
                skip: skip,
                limit: this.paginator.pageSize,
                // exclude big inverters
                maxSize: this.getMaxInverterSize()
            });
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    private getMaxInverterSize(): number | undefined {
        // disable for now
        return 0;

        // return this._systemSize ? (this._systemSize * 1.1) : 0;
    }

    public onSelect(inverter: Solar.Inverter) {
        this._dialogRef.close(inverter);

    }

    public async add() {
        try {
            const dialogRef = this._matDialog.open(InverterEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {}
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    public async copy(inverter: Solar.Inverter, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const newOnverter = CloneTool.clone(inverter);
            delete newOnverter._id;
            delete newOnverter.orgId;
            newOnverter.model += ` (${this._translate.instant("Copy")})`

            const dialogRef = this._matDialog.open(InverterEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    inverter: newOnverter
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public async edit(inverter: Solar.Inverter, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const dialogRef = this._matDialog.open(InverterEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    inverter: inverter
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }
}
