import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'assetUrl'
})
export class AssetUrlPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    return assetUrl(value);
  }
}

export function assetUrl(url: string): string {
  // @ts-ignore
  const publicPath = __webpack_public_path__;
  const publicPathSuffix = publicPath.endsWith('/') ? '' : '/';
  const urlPrefix = url ? (url.startsWith('/') ? '' : '/') : '';
  return `${publicPath}${publicPathSuffix}assets${urlPrefix}${url}`;
}
