import { Component, Input } from '@angular/core';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-permit-ocpd-schedule',
    templateUrl: './permit-ocpd-schedule.component.html',
    styleUrls: ['./permit-ocpd-schedule.component.scss']
})
export class PermitOcpdScheduleComponent {

    @Input() permitScope!: AIKO.PermitScope;


}
