<div *ngIf="permitScope"
     class="component">

    <div class="package-card">

        <div class="title">
            {{"OCPD Schedule" | translate}}
        </div>

        <div class="table-wrapper">

            <table mat-table
                   [dataSource]="permitScope.content.electricalCalc"
                   class="table">
                <ng-container matColumnDef="tag">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"Tag" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record"> {{record.id}} </td>
                </ng-container>

                <ng-container matColumnDef="ocpd">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"OCPD" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record"> {{record.OCPD}} </td>
                </ng-container>

                <ng-container matColumnDef="current">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"CURRENT" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record"> {{record.continiusCurrents}} </td>
                </ng-container>


                <ng-container matColumnDef="validation">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"VALIDATION" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record">
                        <div class="row-formula"
                             *ngIf="record.ocpdCheck">
                            <div>
                                {{record.ocpdCheck.descrption}}
                            </div>
                            <ng-container *ngIf="record.ocpdCheck.descrption != 'N/A'">
                                <div *ngIf="record.ocpdCheck.pass"
                                     class="pass">
                                    {{"OK" | translate}}
                                </div>
                                <div *ngIf="!record.ocpdCheck.pass"
                                     class="failed">
                                    {{"FAILED" | translate}}
                                </div>
                            </ng-container>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="['tag', 'ocpd', 'current', 'validation']; sticky: true"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: ['tag', 'ocpd', 'current', 'validation'];"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row"
                    *matNoDataRow>
                    <td class="mat-cell"
                        colspan="4">{{"No data matching" | translate}}</td>
                </tr>
            </table>
        </div>

    </div>
</div>
