import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Solar, NotifyService, WebSolarModuleService } from '@websolar/ng-websolar';

@Component({
    selector: 'app-module-edit-window',
    templateUrl: './module-edit-window.component.html',
    styleUrls: ['./module-edit-window.component.scss']
})
export class ModuleEditWindowComponent {

    public module: Solar.Module;

    public isLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { module: Solar.Module },
        private _dialogRef: MatDialogRef<ModuleEditWindowComponent>,
        private _notify: NotifyService,
        private _moduleService: WebSolarModuleService
    ) {
        this.module = data.module || this.getDefault();
    }

    private getDefault(): Solar.Module {
        return {
            model: "",
            manufacturer: "",
            width: 1.5,
            height: 1,
            depth: 0.04,
            description: "",
            power: 270,
            isc: 9,
            ipmax: 8,
            misc: 0.05,
            technology: "Mono-c-Si",
            tvoc: -0.3,
            voc: 38,
            vpmax: 30,
            tpmax: -0.3,
            price: 400,
            weight: 30
        }
    }

    public async save() {
        try {
            this.isLoading = true;

            await this._moduleService.save(this.module);

            this._notify.success(`Module saved`);

            this._dialogRef.close(true);
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public isCanSave() {
        if (!this.module.manufacturer ||
            !this.module.model ||
            typeof this.module.width != "number" ||
            typeof this.module.height != "number" ||
            typeof this.module.depth != "number" ||
            typeof this.module.voc != "number" ||
            typeof this.module.tpmax != "number" ||
            typeof this.module.power != "number" ||
            typeof this.module.tvoc != "number") {
            return false;
        }

        return true;
    }

    public close() {
        this._dialogRef.close(null);
    }
}
