import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ModuleEditWindowComponent } from '../module-edit-window/module-edit-window.component';
import { TranslateService } from '@ngx-translate/core';
import { Solar, WebSolarModuleService, NotifyService, WebSolarAuthService } from '@websolar/ng-websolar';
import { CloneTool } from 'src/app/core/clone.tool';

@Component({
    selector: 'app-module-picker-window',
    templateUrl: './module-picker-window.component.html',
    styleUrls: ['./module-picker-window.component.scss']
})
export class ModulePickerWindowComponent implements AfterViewInit {

    @ViewChild("paginator") paginator!: MatPaginator;

    public searchText = "";

    public displayedColumns = ['manufacturer', 'model', 'description', 'power', 'tpmax', 'voc', 'copy'];

    public pageOptions = [10, 20, 30];

    public resultsLength = 0;

    public isLoading = true;

    public modules: Solar.Module[] = [];

    private _timer: unknown;

    private _manufacturerFilter: string[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            manufacturer: string[]
        },
        private _dialogRef: MatDialogRef<ModulePickerWindowComponent>,
        private _moduleService: WebSolarModuleService,
        private _notify: NotifyService,
        private _auth: WebSolarAuthService,
        private _matDialog: MatDialog,
        private _translate: TranslateService
    ) {
        this._manufacturerFilter = data.manufacturer || [];

        if (window.innerWidth < 900) {
            this.displayedColumns = ['manufacturer', 'model', 'power']
        }
    }

    ngAfterViewInit(): void {
        this.reload();
    }

    public async reload() {
        try {
            this.isLoading = true;
            this.modules = [];


            this.resultsLength = await this._moduleService.count({
                search: this.searchText,
                manufacturer: this._manufacturerFilter
            })

            await this.loadPage();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public onSearch() {
        if (this._timer) {
            clearTimeout(this._timer as number)
        }

        this._timer = setTimeout(() => {
            this.paginator.pageIndex = 0;
            this.reload();
        }, 300);
    }


    public async loadPage() {
        try {
            this.isLoading = true;
            this.modules = [];

            const skip = this.paginator.pageIndex * this.paginator.pageSize;

            this.modules = await this._moduleService.find({
                search: this.searchText,
                manufacturer: this._manufacturerFilter,
                skip: skip,
                limit: this.paginator.pageSize
            });
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public onSelect(module: Solar.Module) {
        this._dialogRef.close(module);

    }

    public async add() {
        try {
            if (!this._auth.isSigned()) {
                return;
            }

            const dialogRef = this._matDialog.open(ModuleEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {}
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public async copy(module: Solar.Module, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            if (!this._auth.isSigned()) {
                return;
            }

            const newModule = CloneTool.clone(module);
            delete newModule._id;
            delete newModule.orgId;
            newModule.model += ` (${this._translate.instant("Copy")})`;

            const dialogRef = this._matDialog.open(ModuleEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    module: newModule
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public async edit(module: Solar.Module, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const dialogRef = this._matDialog.open(ModuleEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    module: module
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }
}
