import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Solar } from '@websolar/ng-websolar';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-location-search',
    templateUrl: './location-search.component.html',
    styleUrls: ['./location-search.component.scss']
})
export class LocationSearchComponent {

    @Output() locationChange = new EventEmitter<Solar.GeoLocation>();

    @Input() placeholder = "";

    public type: "google" | "amap" = "google";

    constructor() {
        this.type = environment.region == "cn" ? "amap" : "google";
    }

    public onLocationChange(location: Solar.GeoLocation) {
        this.locationChange.emit(location);
    }

}
