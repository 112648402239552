import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { NotifyService, Solar, SolarInstance, WebSolarWiringService } from '@websolar/ng-websolar';
import { DialogService } from 'src/app/services/dialog.service';

interface TreeNodeAction {
    name: string;

    object: Solar.Object;

    pairedWith?: Solar.PairedType;
}

@Component({
    selector: 'app-tree-config-node',
    templateUrl: './tree-config-node.component.html',
    styleUrls: ['./tree-config-node.component.scss']
})
export class TreeConfigNodeComponent implements OnChanges {

    @Input() parent!: { children: Solar.StringConfigItem[] };

    @Input() node!: Solar.StringConfigItem;

    @Input() instance!: SolarInstance;

    @Input() config!: Solar.StringsConfig;

    @Input() project!: Solar.Project;

    @Input() eventsEmitter = new Subject<{ name: string, params: unknown }>();

    public inverterIndex = 0;

    public actions: TreeNodeAction[] = [];

    public infoItems: { icon: string; text: string, color?: string }[] = [];

    /**
     * Can remove delete
     */
    public canDelete = true;

    constructor(
        private _notify: NotifyService,
        private _dialogService: DialogService,
        private _wiringService: WebSolarWiringService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.node && this.config && this.instance && this.project) {
            this.refresh();
        }

        if (changes["eventsEmitter"]) {
            this.eventsEmitter.asObservable().subscribe((opt) => {
                if (opt.name == "refresh") {
                    this.refresh();
                }
            })
        }
    }

    public refresh() {
        this.actions = this.getActions();
        this.infoItems = this.getInfoItems();
    }

    public getActions() {
        const output: TreeNodeAction[] = [];

        this.canDelete = true;

        const options = this.getActionsOptions();

        // TODO: Disabled junction box and combiner box for now
        // Probably will be activted in the next releases

        if (this.node.object.type == "main_service_panel") {

            output.push(...options.inverters)
            // output.push(...options.disconnects)
            // output.push(options.combinerBox)
        }
        else if (this.node.object.type == "junction_box") {
            // strings
            output.push(...options.inverters)
        }
        else if (this.node.object.type == "combiner_box") {
            // junction box
            // output.push(options.junctionBox);

            // strings
            output.push(...options.inverters)
        }
        else if (this.node.object.type == "ac_disconnect") {
            // combiner box
            // output.push(options.combinerBox);

            // junction box
            // output.push(options.junctionBox);

            output.push(...options.inverters)
        }

        return output;
    }


    private getActionsOptions() {
        const inverters = this.instance.getObjects({ types: ["inverter"] }) as Solar.ObjectInverter[];
        const acDisconnects = this.instance.getObjects({ types: ["ac_disconnect"] }) as Solar.ObjectACDisconnect[];

        const flatTree = this._wiringService.getFlatTree(this.config.items);
        const idsInUse = flatTree.filter(i => i.object).map(i => i.object.id);

        const combinerBox = {
            name: `Combiner Box`,
            object: { name: "Combiner Box", type: "combiner_box" } as Solar.ObjectCombinerBox
        } as TreeNodeAction;

        const junctionBox = {
            name: `Junction Box`,
            object: { name: "Junction Box", type: "junction_box" } as Solar.ObjectJunctionBox
        } as TreeNodeAction;


        const options = {
            inverters: new Array<TreeNodeAction>(),
            disconnects: new Array<TreeNodeAction>(),
            strings: new Array<TreeNodeAction>(),
            junctionBox: junctionBox,
            combinerBox: combinerBox
        };

        // inverters
        for (const inv of inverters) {
            if (!inv.inverter ||
                inv.inverter.microinverter) {
                continue;
            }
            if (idsInUse.includes(inv.id)) {
                let isTopLevel = this.config.items.find(i => i.object.id == inv.id);
                if (!isTopLevel) {
                    continue;
                }
            }
            options.inverters.push({
                name: `${inv.inverter.manufacturer} ${inv.inverter.model}`,
                object: inv
            });
        }

        // ac disconnects
        for (const disconnect of acDisconnects) {
            if (idsInUse.includes(disconnect.id)) {
                continue;
            }
            options.disconnects.push({
                name: `${disconnect.name}`,
                object: disconnect
            });
        }

        return options;
    }

    public addComponent(action: TreeNodeAction) {
        try {
            if (!this.node) {
                console.error("not is not available")
                return;
            }

            // check if it already connected to top level
            const topLevelIdx = this.project.electrical.stringsConfig.items.findIndex(i => i.object.id == action.object.id);
            if (topLevelIdx >= 0) {
                const topLevelItem = this.project.electrical.stringsConfig.items[topLevelIdx];
                // remove it from the top level
                this.project.electrical.stringsConfig.items.splice(topLevelIdx, 1);

                this.node.children.push(topLevelItem);
            }
            else {
                const newItem: Solar.StringConfigItem = {
                    name: action.name,
                    object: action.object,
                    pairedWith: action.pairedWith,
                    children: []
                };
                this.node.children.push(newItem);
            }

            // update actions
            this.actions = this.getActions();

            // notify all components
            this.eventsEmitter.next({ name: "refresh", params: null });
        }
        catch (err) {
            this._notify.error(err);
        }
    }



    private update3dObject(str: Solar.Object) {
        this.instance.removeObjects({ id: str.id });
        // add new 3d object
        const newObj = this.instance.createObject(str, this.project.measurement);
        if (newObj) {
            this.instance.scene.add(newObj);
        }
    }

    public async deleteNode(node: Solar.StringConfigItem) {

        if (this.parent) {
            const idx = this.parent.children.indexOf(node);
            this.parent.children.splice(idx, 1);

            if (node.object.type == "inverter") {
                // move the inverter back to the top level
                this.project.electrical.stringsConfig.items.push(node);
            } else {
                const flatTree = this._wiringService.getFlatTree(node.children);
                const invItems = flatTree.filter(i => i.object.type == "inverter");
                for (const inverterItem of invItems) {
                    // move the inverter back to the top level
                    this.project.electrical.stringsConfig.items.push(inverterItem);
                }

                // remove the from the parents
                for (const item of flatTree) {
                    for (const inverterItem of invItems) {
                        const idx = item.children.indexOf(inverterItem);
                        if (idx >= 0) {
                            // remove it 
                            item.children.splice(idx, 1);
                        }
                    }
                }
            }
        }

        // update actions
        this.actions = this.getActions();

        // notify all components
        this.eventsEmitter.next({ name: "refresh", params: null });
    }

    public getInfoItems() {
        const output: { color?: string, icon: string; text: string }[] = [];

        if (this.node.object.type == "inverter") {
            const inv = this.node.object as Solar.ObjectInverter;
            const inverter = inv.inverter;
            if (inverter) {

                if (inv.color) {
                    output.push({
                        color: inv.color,
                        icon: "",
                        text: ""
                    })
                }

                const text = `${inverter.minVoltage}V - ${inverter.maxVoltage}V`
                output.push({
                    icon: "bolt",
                    text: text
                })
            }
        }
        else if (this.node.object.type == "combiner_box") {
            const strings = this._wiringService.getFlatTree(this.node.children).filter(s => s.object.type == "string");
            if (strings.length) {
                let currentSum = 0;
                for (const strItem of strings) {
                    currentSum += this.getStringCurrent();
                }
                const text = `${Math.round(currentSum * 10) / 10}A`
                output.push({
                    icon: "bolt",
                    text: text
                })
            }
        }
        else if (this.node.object.type == "main_service_panel") {
            const panel = this.node.object as Solar.ObjectMainServicePanel;
            let text = `${panel.mainBreaker} A`
            if (panel.pvBreaker) {
                text += ` | ${panel.pvBreaker} A`

            }
            output.push({
                icon: "bolt",
                text: text
            })

        }
        return output;
    }


    private getStringCurrent(): number {

        const segments = this.instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
        const module = segments.find(s => s.module)?.module || null;
        if (!module) {
            return 0;
        }
        return module.isc;
    }


    /**
     * Formats the given index by padding it with leading zeros.
     * 
     * @param index - The index to format.
     * @returns The formatted index as a string.
     */
    public formatIndex(index: number) {
        return index.toString().padStart(2, '0');
    }

    public getInverter(item: Solar.StringConfigItem) {
        return (item.object as Solar.ObjectInverter);
    }
}
