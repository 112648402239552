<div class="location-container"
     [ngSwitch]="type">
    <app-google-location-search *ngSwitchCase="'google'"
                                [placeholder]="placeholder"
                                (locationChange)="onLocationChange($event)"></app-google-location-search>

    <app-amap-location-search *ngSwitchCase="'amap'"
                              [placeholder]="placeholder"
                              (locationChange)="onLocationChange($event)"></app-amap-location-search>
</div>
