/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Component,
    Input,
    ViewEncapsulation,
    Output,
    EventEmitter,
    ContentChild,
    TemplateRef,
    TrackByFunction,
    SimpleChanges,
    OnChanges,
    NgZone,
    ChangeDetectorRef,
    ElementRef,
    Inject,
    PLATFORM_ID
} from '@angular/core';

import { curveLinear, line } from 'd3-shape';
import { scaleBand, scaleLinear, scalePoint, scaleTime } from 'd3-scale';
import {
    BaseChartComponent,
    ViewDimensions,
    ColorHelper,
    calculateViewDimensions,
    ScaleType,
    Color,
    BarOrientation,
    DataItem,
    LegendOptions,
    LegendPosition
} from '@swimlane/ngx-charts';
import { Solar, WebSolarApp } from '@websolar/ng-websolar';
import { Observable } from 'rxjs';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-report-production',
    templateUrl: './report-production.component.html',
    styleUrls: ['./report-production.component.scss']
})
export class ReportProductionComponent extends BaseChartComponent implements OnChanges {

    @Input() legendVisible = true;

    @Input() legendPosition: LegendPosition = LegendPosition.Right;
    @Input() xAxis = true;
    @Input() yAxis = true;
    @Input() showXAxisLabel = false;
    @Input() showYAxisLabel = true;
    @Input() xAxisLabel!: string;
    @Input() yAxisLabel!: string;
    @Input() tooltipDisabled: boolean = false;
    @Input() scaleType: ScaleType = ScaleType.Ordinal;
    @Input() gradient!: boolean;
    @Input() showGridLines: boolean = true;
    @Input() activeEntries: any[] = [];
    @Input() trimXAxisTicks: boolean = true;
    @Input() trimYAxisTicks: boolean = true;
    @Input() rotateXAxisTicks: boolean = true;

    @Input() maxXAxisTickLength: number = 16;

    @Input() maxYAxisTickLength: number = 16;

    @Input() groupPadding: number = 12;

    @Input() barPadding: number = 1;

    @Input() roundDomains: boolean = false;

    @Input() roundEdges: boolean = false;

    @Input() yScaleMax!: number;

    @Input() showDataLabel: boolean = false;


    @Input() noBarWhenZero: boolean = true;

    @Input() wrapTicks = false;

    @Output() activate: EventEmitter<any> = new EventEmitter();
    @Output() deactivate: EventEmitter<any> = new EventEmitter();

    @ContentChild('tooltipTemplate') tooltipTemplate!: TemplateRef<any>;

    @Input() project!: Solar.Project;

    @Input() consumption!: WebSolarApp.ConsumptionOutput;

    @Input() events!: Observable<{ name: string, params: unknown }>;

    public dims!: ViewDimensions;

    public groupDomain!: string[];

    public innerDomain!: string[];

    public valueDomain!: [number, number];

    public groupScale: any;

    public innerScale: any;

    public valueScale: any;

    public transform!: string;

    public colors!: ColorHelper;

    public margin: number[] = [10, 20, 10, 20];

    public xAxisHeight: number = 0;

    public yAxisWidth: number = 0;

    public legendOptions!: LegendOptions;

    public dataLabelMaxHeight: any = { negative: 0, positive: 0 };

    public barOrientation = BarOrientation;

    /**
     * Represents the line chart data for the report production component.
     */
    public lineChart: { path: string, pathBg: string }[] = [];

    /**
     * The curve used for the line chart
     */
    public curve = curveLinear;

    /**
     * Represents the line data for the report production component.
     */
    public lineData: { name: string, xShift: number, value: number }[] = [];

    constructor(
        chartElement: ElementRef,
        zone: NgZone,
        cd: ChangeDetectorRef,
        @Inject(PLATFORM_ID) platformId: any,
        private _translate: TranslateService
    ) {
        super(chartElement, zone, cd, platformId);
    }

    override ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);

        if (this.project && this.consumption) {
            this.rebuild();
        }

        if (changes["events"]) {
            this.events.subscribe((opt) => {
                if (opt.name == "rebuild") {
                    this.rebuild();
                }
            })
        }
    }


    /**
     * Rebuilds the report data.
     * 
     * @remarks
     * This method populates the `results` and `lineData` arrays with data for each month.
     * Finally, it calls the `update` method to update the chart.
     * 
     * @returns A Promise that resolves when the rebuild is complete.
     * 
     * @throws If an error occurs during the rebuild process.
     */
    private async rebuild() {
        try {
            if (!this.project) {
                return;
            }
            this.results = [];

            this.scheme = {
                domain: ["#fa5002", "#fb8801"]
            } as Color;

            this.xAxisLabel = this._translate.instant("Production vs Consumption");
            this.yAxisLabel = this._translate.instant("kWh");

            this.lineChart = [];

            this.lineData = [];

            for (let month = 0; month < 12; month++) {
                const monthName = this._translate.instant(moment().month(month).format("MMM"));

                const consumpMonth = this.consumption.months.find(m => m.month == month);

                this.results.push({
                    name: monthName,
                    series: [
                        { name: this._translate.instant("Production"), value: consumpMonth?.produced || 0 },
                        { name: this._translate.instant("Consumption"), value: consumpMonth?.totalConsumed || 0 }
                    ]
                });

                this.lineData.push({ name: monthName, xShift: 0, value: consumpMonth?.selfConsumed || 0 });
            }

            this.update();
        }
        catch (err) {
            console.error(err);
        }
    }

    /**
     * Returns a line generator function that generates a line based on the provided data.
     * The line is defined by the x and y coordinates of each data point.
     * 
     * @returns The line generator function.
     */
    private getLineGenerator(): any {
        return line<any>()
            .x(d => {
                const label = d.name;
                const xShift = d.xShift || 0;
                let value;
                if (this.scaleType === ScaleType.Time) {
                    value = this.groupScale(label);
                } else if (this.scaleType === ScaleType.Linear) {
                    value = this.groupScale(Number(label));
                } else {
                    value = this.groupScale(label);
                }

                return value + xShift;
            })
            .y(d => {
                const out = this.valueScale(d.value);
                return out;
            })
            .curve(this.curve);
    }

    /**
     * Updates the component with new data and recalculates the necessary dimensions and scales.
     */
    override update(): void {
        super.update();

        if (!this.showDataLabel) {
            this.dataLabelMaxHeight = { negative: 0, positive: 0 };
        }
        this.margin = [10 + this.dataLabelMaxHeight.positive, 20, 10 + this.dataLabelMaxHeight.negative, 20];

        this.height = 300;

        this.dims = calculateViewDimensions({
            width: this.width,
            height: this.height,
            margins: this.margin,
            showXAxis: this.xAxis,
            showYAxis: this.yAxis,
            xAxisHeight: this.xAxisHeight,
            yAxisWidth: this.yAxisWidth,
            showXLabel: this.showXAxisLabel,
            showYLabel: this.showYAxisLabel,
            showLegend: false,
            legendType: this.schemeType,
            legendPosition: this.legendPosition
        });

        if (this.showDataLabel) {
            this.dims.height -= this.dataLabelMaxHeight.negative;
        }

        this.formatDates();

        this.groupDomain = this.getGroupDomain();
        this.innerDomain = this.getInnerDomain();
        this.valueDomain = this.getValueDomain();

        this.groupScale = this.getGroupScale();
        this.innerScale = this.getInnerScale();
        this.valueScale = this.getValueScale();

        this.setColors();
        this.legendOptions = this.getLegendOptions();
        this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;

        this.lineChart = [];
        if (this.lineData.length) {
            const lineGen = this.getLineGenerator();
            const barWidth = (this.dims.width / 12) / 2;
            for (const pnt of this.lineData) {
                const path = lineGen([
                    { name: pnt.name, xShift: 1, value: pnt.value },
                    { name: pnt.name, xShift: barWidth - 3, value: pnt.value }
                ]);

                const pathBg = lineGen([
                    { name: pnt.name, xShift: 0, value: pnt.value },
                    { name: pnt.name, xShift: barWidth, value: pnt.value }
                ]);

                this.lineChart.push({
                    path: path,
                    pathBg: pathBg
                })
            }
        }
    }

    onDataLabelMaxHeightChanged(event: any, groupIndex: number): void {
        if (event.size.negative) {
            this.dataLabelMaxHeight.negative = Math.max(this.dataLabelMaxHeight.negative, event.size.height);
        } else {
            this.dataLabelMaxHeight.positive = Math.max(this.dataLabelMaxHeight.positive, event.size.height);
        }
        if (groupIndex === this.results.length - 1) {
            setTimeout(() => this.update());
        }
    }

    private getGroupScale() {
        const spacing = this.groupDomain.length / (this.dims.height / this.groupPadding + 1);

        return scaleBand()
            .rangeRound([0, this.dims.width])
            .paddingInner(spacing)
            .paddingOuter(spacing / 2)
            .domain(this.groupDomain);
    }

    private getInnerScale(): any {
        const width = this.groupScale.bandwidth();
        const spacing = this.innerDomain.length / (width / this.barPadding + 1);
        return scaleBand().rangeRound([0, width]).paddingInner(spacing).domain(this.innerDomain);
    }

    private getValueScale(): any {
        const scale = scaleLinear().range([this.dims.height, 0]).domain(this.valueDomain);
        return this.roundDomains ? scale.nice() : scale;
    }

    private getGroupDomain(): string[] {
        const domain: any = [];
        for (const group of this.results) {
            if (!domain.includes(group.label)) {
                domain.push(group.label);
            }
        }

        return domain;
    }

    private getInnerDomain(): string[] {
        const domain: any = [];
        for (const group of this.results) {
            for (const d of group.series) {
                if (!domain.includes(d.label)) {
                    domain.push(d.label);
                }
            }
        }

        return domain;
    }

    private getValueDomain(): [number, number] {
        const domain: any = [];
        for (const group of this.results) {
            for (const d of group.series) {
                if (!domain.includes(d.value)) {
                    domain.push(d.value);
                }
            }
        }

        const min = Math.min(0, ...domain);
        const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(0, ...domain);

        return [min, max];
    }

    public groupTransform(group: DataItem): string {
        return `translate(${this.groupScale(group.label)}, 0)`;
    }

    onClick(data: any, group?: DataItem): void {
        if (group) {
            data.series = group.name;
        }

        this.select.emit(data);
    }

    trackBy: TrackByFunction<DataItem> = (index: number, item: DataItem) => {
        return item.name;
    };

    setColors(): void {
        let domain;
        if (this.schemeType === ScaleType.Ordinal) {
            domain = this.innerDomain;
        } else {
            domain = this.valueDomain;
        }

        this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
    }

    getLegendOptions(): LegendOptions {
        const opts = {
            scaleType: this.schemeType as any,
            colors: undefined,
            domain: [],
            title: undefined,
            position: this.legendPosition
        } as any;
        if (opts.scaleType === ScaleType.Ordinal) {
            opts.domain = this.innerDomain;
            opts.colors = this.colors;
        } else {
            opts.domain = this.valueDomain;
            opts.colors = this.colors.scale;
        }

        return opts;
    }

    updateYAxisWidth({ width }: { width: number }): void {
        this.yAxisWidth = width;
        this.update();
    }

    updateXAxisHeight({ height }: { height: number }): void {
        this.xAxisHeight = height;
        this.update();
    }

    onActivate(event: any, group: DataItem | null, fromLegend: boolean = false): void {
        const item = Object.assign({}, event);
        if (group) {
            item.series = group.name;
        }

        const items = this.results
            .map((g: any) => g.series)
            .flat()
            .filter((i: any) => {
                if (fromLegend) {
                    return i.label === item.name;
                } else {
                    return i.name === item.name && i.series === item.series;
                }
            });

        this.activeEntries = [...items];
        this.activate.emit({ value: item, entries: this.activeEntries });
    }

    onDeactivate(event: any, group: DataItem | null, fromLegend: boolean = false): void {
        const item = Object.assign({}, event);
        if (group) {
            item.series = group.name;
        }

        this.activeEntries = this.activeEntries.filter(i => {
            if (fromLegend) {
                return i.label !== item.name;
            } else {
                return !(i.name === item.name && i.series === item.series);
            }
        });

        this.deactivate.emit({ value: item, entries: this.activeEntries });
    }
}