import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WebSolarApp } from '@websolar/ng-websolar';

@Component({
    selector: 'app-report-consumption-pipe',
    templateUrl: './report-consumption-pipe.component.html',
    styleUrls: ['./report-consumption-pipe.component.scss']
})
export class ReportConsumptionPipeComponent implements OnChanges {

    @Input() consumption!: WebSolarApp.ConsumptionOutput;

    public scheme = {
        domain: ["#fa5002", "#fb8801"]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    public results: { name: string, value: number }[] = [];

    /**
     * self consume in MWh
     */
    public selfConsume = 0;

    public selfConsumePercent = 0;

    /**
     * import in MWh
     */
    public export = 0;

    public exportPercent = 0;

    /**
     * total in MWh
     */
    public total = 0;

    constructor(
        private _translate: TranslateService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.consumption) {
            this.rebuild();
        }
    }

    private rebuild() {
        try {


            this.total = this.consumption.months
                .reduce((prev, cur) => prev + cur.produced, 0)
            // convert  to MWh Production
            this.total = Math.round(this.total / 10) / 100;

            this.selfConsume = this.consumption.months
                .reduce((prev, cur) => prev + cur.selfConsumed, 0)
            // convert  to MWh
            this.selfConsume = Math.round(this.selfConsume / 10) / 100;
            this.selfConsumePercent = Math.round(this.selfConsume / this.total * 100);

            this.export = Math.round((this.total - this.selfConsume) * 10) / 10;
            this.exportPercent = Math.round(100 - this.selfConsumePercent);

            this.results = [
                { name: this._translate.instant("Solar Consumption"), value: this.selfConsume },
                { name: this._translate.instant("Grid Export"), value: this.export }
            ]
        }
        catch (err) {
            console.error(err);
        }
    }

}
