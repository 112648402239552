import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Solar, SolarInstance, WebSolarApp, NotifyService, WebSolarEventsService, WebSolarWiringService } from '@websolar/ng-websolar';
import { Observable } from 'rxjs';
import { Geometry } from 'src/app/core/geometry';
import { DialogService } from 'src/app/services/dialog.service';
import { ModuleConfigService } from 'src/app/services/module.config.service';

@Component({
    selector: 'app-segments-list',
    templateUrl: './segments-list.component.html',
    styleUrls: ['./segments-list.component.scss']
})
export class SegmentsListComponent implements OnChanges {

    @Input() project!: Solar.Project;

    @Input() instance!: SolarInstance;

    @Output() deleteSegment = new EventEmitter<Solar.ObjectRooftopSegment>();

    @Output() segmentChange = new EventEmitter<Solar.ObjectRooftopSegment>();

    @Output() navigationRequest = new EventEmitter<WebSolarApp.MenuMode>();

    @Output() toolActivated = new EventEmitter<{ type: Solar.ToolType, params: unknown }>();

    @Input() events!: Observable<{ name: string, params: unknown }>;

    public segmentState: { [key: string]: { expanded: boolean } } = {};

    public segments: Solar.ObjectRooftopSegment[] = [];

    public total?: {
        count: number,
        power: number,
        area: number
    };

    constructor(
        private _dialogService: DialogService,
        private _notify: NotifyService,
        private _translate: TranslateService,
        private _eventService: WebSolarEventsService,
        private _moduleConfigService: ModuleConfigService
    ) {
        this.attachToEvents();

    }


    private attachToEvents() {
        this._eventService.events.subscribe((opt) => {

            try {
                if (opt.name == "tool_completed" ||
                    opt.name == "object_deleted" ||
                    opt.name == "project_initalized" ||
                    opt.name == "refresh" ||
                    opt.name == "undo" ||
                    opt.name == "redo") {
                    this.updateList();
                }
                else if (opt.name == "object_picked") {
                    this.onObjectPicked(opt.params as Solar.Object);
                }
                this.updateTotal();
            }
            catch (err) {
                console.error(err);
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["instance"] && this.instance) {

            this.updateList();
            this.updateTotal();
        }
    }

    private updateList() {
        if (!this.instance || !this.project) {
            return;
        }
        this.segments = this.instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
        for (const segment of this.segments) {
            this.initSegment(segment);
        }

        if (this.segments.length == 1) {
            // expand it
            this.segmentState[this.segments[0].id].expanded = true;
        }

    }

    public onNewSegment() {
        this.toolActivated.emit({ type: "segment", params: null });
    }

    public toggle(segment: Solar.ObjectRooftopSegment) {
        // collapse other segments
        for (const key of Object.keys(this.segmentState)) {
            if (key != segment.id) {
                this.segmentState[key].expanded = false;
            }
        }

        this.segmentState[segment.id].expanded = !this.segmentState[segment.id].expanded;

        this.highlightSegment();
    }

    private highlightSegment() {
        if (!this.instance) {
            return;
        }
    }

    /**
     * Handles the event when an object is picked.
     * If the picked object is not of type "segment", it returns early.
     * Collapses other segments and expands the picked segment.
     * Finally, it highlights the segment.
     * 
     * @param object - The picked object.
     */
    private onObjectPicked(object: Solar.Object) {
        if (object.type != "segment") {
            return;
        }

        // collapse other segments
        for (const key of Object.keys(this.segmentState)) {
            if (key != object.id) {
                this.segmentState[key].expanded = false;
            }
        }

        this.segmentState[object.id].expanded = true;

        this.highlightSegment();
    }

    /**
     * Initializes a rooftop segment.
     * 
     * @param segment - The rooftop segment to initialize.
     */
    private initSegment(segment: Solar.ObjectRooftopSegment) {
        this.segmentState[segment.id] = {
            expanded: false
        };

        if (!segment.align) {
            segment.align = "grid";
        }

        if (!segment.output) {
            // set default
            segment.output = {
                power: 0,
                count: 0,
                area: 0
            };
        }

        if (segment.module) {
            segment.isAutoPlacement = true;
        }

        if (this.instance) {
            const modules = this.instance.getObjects({ types: ["module"], ownerId: segment.id });
            const power = segment.module ? Math.round((modules.length * segment.module.power) / 100) / 10 : 0;
            segment.output.count = modules.length;
            segment.output.power = power;
            segment.output.area = Math.round(Geometry.getArea(segment.points));
        }
    }

    /**
     * Handles the change event of a rooftop segment.
     * @param segment - The selected rooftop segment.
     */
    public onSegmentChange(segment: Solar.ObjectRooftopSegment) {

        const nrModules = this.instance.getObjects({ types: ["module"] }).length;

        // rebuild the segments
        this.instance.rebuild(this.project, [segment.id], true);
        // mark the project simulation as not done
        this.project.simulationStatus = "";

        const modulesNew = this.instance.getObjects({ types: ["module"] }) as Solar.ObjectModule[];
        if (nrModules != modulesNew.length) {
            // clear the electrical configuration
            this.project.electrical.stringsConfig = {
                items: [],
                isAuto: false
            };
            // remove the inverters
            this.instance.removeObjects({ types: ["inverter"] });
        }

        // remove optimizers and strings
        this.instance.removeObjects({ types: ["optimizer", "string"] });
        for (const m of modulesNew) {
            m.optimizerId = "";
        }


        this.segmentChange.emit(segment)
    }

    public onToolActivated(opt: Solar.ToolOptions) {
        this.toolActivated.emit(opt)
    }

    /**
     * Deletes a segment from the rooftop.
     * @param segment - The segment to be deleted.
     */
    public async onDeleteSegment(segment: Solar.ObjectRooftopSegment) {
        try {
            const confirm = await this._dialogService.confirm({
                title: `${this._translate.instant("Delete segment")} "${segment.name}"`,
                text: `Are you sure you want to delete this segment ?`,
                okBtn: "Delete"
            })
            if (!confirm) {
                return;
            }

            // remove the segment and dependenices from the scene
            this.instance.removeObjects({ id: segment.id });
            this.instance.removeObjects({ ownerId: segment.id });

            // mark the project simulation as not done
            this.project.simulationStatus = "";

            // send notification
            this._eventService.send({ name: "object_deleted", params: segment })

            this.updateList();
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    /**
     * Updates the total area, count, and power based on the segments data.
     */
    private updateTotal() {
        if (!this.segments) {
            return;
        }
        this.total = {
            area: 0,
            count: 0,
            power: 0
        }

        for (const segment of this.segments) {
            if (!segment.output) {
                continue;
            }
            this.total.area += segment.output.area;
            this.total.power += segment.output.power;
            this.total.count += segment.output.count;
        }

        this.total.area = Math.round(this.total.area * 10) / 10;
        this.total.power = Math.round(this.total.power * 10) / 10;
        this.total.count = Math.round(this.total.count);
    }

    /**
     * Handles the module change event.
     * Sets the new module to all segments and rebuilds the instance.
     */
    public onModuleChange() {
        // set the new module to all segments
        const segments = this.instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
        for (const segment of segments) {
            if (segment.isAutoPlacement) {
                segment.module = this.project.baseConfiguration.module;
                this._moduleConfigService.setDefaultSpacing(segment);
            }
        }


        // rebuild all
        this.instance.rebuild(this.project);
    }

    /**
     * Handles the change event when a segment is installed or uninstalled.
     * @param segment - The segment object representing the rooftop segment.
     */
    public onSegmentInstallChange(segment: Solar.ObjectRooftopSegment) {
        if (!this.project) {
            return;
        }
        if (segment.isAutoPlacement) {
            segment.module = this.project.baseConfiguration.module;
        }
        else {
            segment.module = null;
        }

        // mark the project simulation as not done
        this.project.simulationStatus = "";

        this.instance.rebuild(this.project);
    }


}
