<div class="component">
    <h2>{{"Configuration" | translate}}</h2>
    <div class="config-btns">
        <button mat-stroked-button
                (click)="clearConfig()">
            <mat-icon>clear</mat-icon>
            {{"Clear Configuration" | translate}}
        </button>
    </div>

    <div class="service-panel"
         *ngFor="let servicePanel of servicePanels">
        <div class="service-panel-content">
            <div class="desc">
                <mat-icon class="large-icon"
                          inline="true">bolt</mat-icon>
                <div>
                    <div> {{'Main Service Panel' | translate}}</div>
                    <div class="details">
                        <mat-icon inline="true">bolt</mat-icon>
                        {{getPanel(servicePanel).raiting}}A | {{getPanel(servicePanel).pvBreaker}}A
                    </div>
                </div>
            </div>

            <mat-menu #menu="matMenu">
                <button mat-menu-item
                        *ngFor="let action of actions"
                        (click)="addComponent(servicePanel, action.object)">
                    {{action.name | translate}}
                </button>
            </mat-menu>

            <button mat-icon-button
                    color="primary"
                    [matMenuTriggerFor]="menu">
                <mat-icon>add</mat-icon>
            </button>

            <button mat-icon-button
                    color="primary"
                    (click)="deletePanel(servicePanel)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>

        <div class="inverters-list"
             *ngIf="servicePanel.children">
            <div *ngFor="let child of servicePanel.children">
                <app-tree-config-node [node]="child"
                                      [parent]="servicePanel"
                                      [instance]="instance"
                                      [config]="project.electrical.stringsConfig"
                                      [project]="project"
                                      [eventsEmitter]="events"></app-tree-config-node>
            </div>


        </div>
    </div>

</div>
