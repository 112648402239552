<div class="project"
     *ngIf="project && segment">

    <mat-form-field>
        <mat-label>{{"Terrain Category" | translate}}</mat-label>
        <mat-icon matPrefix
                  class="info-icon"
                  (click)="showTerrainHelp($event)">info_outline</mat-icon>
        <mat-select [(ngModel)]="project.mountingSystem.terrainCategory"
                    (ngModelChange)="onOptionsChange()">
            <mat-option *ngFor="let category of terrainCategories"
                        [value]="category.id">
                {{category.name | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <websolar-number-input [label]="'Altitude' | translate"
                           [(value)]="project.location.elevation"
                           (valueChange)="onOptionsChange()"
                           size="large"
                           type="distance"></websolar-number-input>

    <websolar-number-input [label]="'Positive wind load (+ωk)' | translate"
                           [value]="calcRes?.windWePositive || 0"
                           [disabled]="true"
                           (valueChange)="onOptionsChange()"
                           type="load"></websolar-number-input>

    <websolar-number-input [label]="'Negative wind load (-ωk)' | translate"
                           [value]="calcRes?.windWeNegative || 0"
                           [disabled]="true"
                           (valueChange)="onOptionsChange()"
                           type="load"></websolar-number-input>

    <websolar-number-input [label]="'Snow load (Sk)' | translate"
                           [(value)]="project.mountingSystem.snowLoad"
                           (valueChange)="onOptionsChange()"
                           [disabled]="true"
                           type="load"></websolar-number-input>

</div>
