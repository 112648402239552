<div class="component"
     *ngIf="segment">
    <mat-form-field>
        <mat-label>{{'Description' | translate}}</mat-label>
        <input matInput
               [placeholder]="'Enter the segment name' | translate"
               [(ngModel)]="segment.name">
    </mat-form-field>


    <ng-container *ngIf="segment.module">

        <mat-form-field>
            <mat-label>{{'Array rotation angle (degrees)' | translate}}</mat-label>
            <input matInput
                   [matTooltip]="'Array rotation angle, 0° is the east-west direction.' | translate"
                   [(ngModel)]="segment.azimuth"
                   (ngModelChange)="onChangeAzimuth()"
                   type="number"
                   [placeholder]="'Enter the azimuth' | translate"
                   min="0"
                   max="360">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'Racking' | translate}}</mat-label>
            <mat-select [(ngModel)]="segment.racking"
                        (ngModelChange)="onRackingChange()">
                <mat-option *ngFor="let racking of rackings"
                            [value]="racking.id">
                    {{racking.name | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field>
            <mat-label>{{'Orientation' | translate}}</mat-label>
            <mat-select [(ngModel)]="segment.orientation"
                        (ngModelChange)="onOrientationChange()">
                <mat-option *ngFor="let orient of orientations"
                            [value]="orient.id">
                    {{orient.name | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field *ngIf="segment.racking != 'flush_mount'">
            <mat-label>{{'Tilt (degree)' | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="segment.tilt"
                   (ngModelChange)="onChange()"
                   type="number"
                   [placeholder]="'Enter the tilt' | translate"
                   [min]="0"
                   [max]="80">
        </mat-form-field>

        <ng-container *ngIf="segment.orientation == 'horz'">
            <websolar-number-input [label]="'Column spacing' | translate"
                                   [(value)]="segment.moduleSpacing"
                                   (valueChange)="onChange()"
                                   [placeholder]="'Enter the module spacing' | translate"></websolar-number-input>

            <websolar-number-input [label]="'Module spacing' | translate"
                                   [(value)]="segment.rowSpacing"
                                   (valueChange)="onChange()"
                                   [placeholder]="'Enter the spacing' | translate"
                                   [sufixIcon]="segment.racking == 'fixed_tilt' ? 'info' : ''"
                                   [sufixTooltip]="'Click to see sun path' | translate"
                                   (sufixClick)="showSunPath()"></websolar-number-input>
        </ng-container>

        <ng-container *ngIf="segment.orientation == 'vert'">
            <websolar-number-input [label]="'Row spacing' | translate"
                                   [(value)]="segment.rowSpacing"
                                   (valueChange)="onChange()"
                                   [placeholder]="'Enter the spacing' | translate"
                                   [sufixIcon]="segment.racking == 'fixed_tilt' ? 'info' : ''"
                                   [sufixTooltip]="'Click to see sun path' | translate"
                                   (sufixClick)="showSunPath()"></websolar-number-input>

            <websolar-number-input [label]="'Module spacing' | translate"
                                   [(value)]="segment.moduleSpacing"
                                   (valueChange)="onChange()"
                                   [placeholder]="'Enter the module spacing' | translate"></websolar-number-input>
        </ng-container>


        <mat-form-field>
            <mat-label>{{'Alignment mode' | translate}}</mat-label>
            <mat-select [(ngModel)]="segment.align"
                        (ngModelChange)="onChange()">
                <mat-option *ngFor="let align of aligns"
                            [value]="align.id">
                    {{align.name | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <websolar-number-input [label]="'Setback' | translate"
                               [(value)]="segment.setback"
                               (valueChange)="onChange()"
                               [placeholder]="'Enter the setback' | translate"></websolar-number-input>
    </ng-container>


</div>
