import { Observable } from 'rxjs';
import { debounceTime, switchMap, startWith } from 'rxjs/operators';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NotifyService, Solar } from '@websolar/ng-websolar';
import { FormControl } from '@angular/forms';
import { AMapAutoComplete, AMapAutoCompleteResultTip } from 'src/app/types/amap.types';
import { AMapMapService } from 'src/app/services/amap.map.service';

@Component({
    selector: 'app-amap-location-search',
    templateUrl: './amap-location-search.component.html',
    styleUrls: ['./amap-location-search.component.scss']
})
export class AmapLocationSearchComponent implements AfterViewInit {

    @ViewChild("search") _searchInput!: ElementRef<HTMLInputElement>;

    @Output() locationChange = new EventEmitter<Solar.GeoLocation>();

    @Input() placeholder = "";

    public resultOptions!: Observable<AMapAutoCompleteResultTip[]>;

    public control = new FormControl('');

    private _autoComplete: AMapAutoComplete | undefined;

    constructor(
        private _notify: NotifyService,
        private _mapService: AMapMapService
    ) { }

    public async ngAfterViewInit() {
        await this._mapService.waitAMapInitialization();
        this.initAutoComplete();
    }

    /**
     * Initializes the auto-complete functionality for location search.
     */
    private initAutoComplete() {
        try {
            AMap.plugin('AMap.AutoComplete', () => {
                // Original referenece: https://lbs.amap.com/demo/javascript-api-v2/example/input/get-input-data
                // TODO: the typing doesn't contains information about AutoComplete
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const reference = (window["AMap"] as any);
                this._autoComplete = new reference["AutoComplete"]({}) as AMapAutoComplete;

                this.resultOptions = this.control.valueChanges.pipe(
                    startWith(''),
                    debounceTime(500),
                    switchMap(value => this.search(value || ''))
                );
            });
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    private search(searchValue: string | AMapAutoCompleteResultTip): Promise<AMapAutoCompleteResultTip[]> {
        return new Promise((resolve, reject) => {
            if (!this._autoComplete) {
                reject();
            } else {
                // console.log("searched value:", searchValue);

                if (typeof searchValue == "string") {
                    this._autoComplete.search(searchValue, (status, result) => {
                        if (status == "complete") {
                            resolve((result.tips || []).filter(r => r.location));
                        }
                        else {
                            resolve([]);
                        }
                    });
                }
                else {
                    if (searchValue && searchValue.location) {

                        this.locationChange.emit({
                            name: searchValue.name,
                            lat: searchValue.location.getLat(),
                            lng: searchValue.location.getLng()
                        } as Solar.GeoLocation);

                        resolve([]);
                    }
                }
            }
        });
    }

    public showName(obj: string | AMapAutoCompleteResultTip) {
        if (!obj) {
            return "";
        }
        if (typeof obj == "string") {
            return obj;
        }
        return obj.name;
    }
}
