import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    Solar,
    SolarInstance,
    WebSolarApp,
    WebSolarProjectService,
    NotifyService, WebSolarObjectService,
    WebSolarEventsService,
    WebSolarProjectStateService,
    WebSolarTransactionService,
    WebSolarModuleService,
    WebSolarInverterService,
    WebSolarOptimizerService,
    WebSolarWiringService,
    WebSolarGeometryService
} from '@websolar/ng-websolar';
import { DialogService } from 'src/app/services/dialog.service';
import { OptimizerService } from 'src/app/services/optimizer.service';

@Component({
    selector: 'app-design-page',
    templateUrl: './design-page.component.html',
    styleUrls: ['./design-page.component.scss']
})
export class DesignPageComponent implements OnInit, OnDestroy {

    public project!: Solar.Project;

    public objects!: Solar.Object[];

    public instance!: SolarInstance;

    public legend?: WebSolarApp.Legend;

    public mode: WebSolarApp.MenuMode = "Design";

    /**
     * Design toolbar state
     */
    public toolbarState = {
        viewToolsVisible: true,
        designToolVisible: true
    }

    public isLoading = true;

    public fullWidthModes = [
        "Reports", "Mounting"
    ] as WebSolarApp.MenuMode[];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _notify: NotifyService,
        private _eventService: WebSolarEventsService,
        private _projectService: WebSolarProjectService,
        private _objService: WebSolarObjectService,
        private _state: WebSolarProjectStateService,
        private _transactionService: WebSolarTransactionService,
        private _moduleService: WebSolarModuleService,
        private _inverterService: WebSolarInverterService,
        private _wsOptimizerService: WebSolarOptimizerService,
        private _dialogService: DialogService,
        private _wiringService: WebSolarWiringService,
        private _geometryService: WebSolarGeometryService
    ) { }

    /**
     * Initializes the component after Angular has initialized all data-bound properties.
     * This method is called right after the component's data-bound properties have been checked for the first time.
     * It is commonly used for initialization tasks such as retrieving data from a server.
     */
    public async ngOnInit() {
        try {
            const query = this._activatedRoute.snapshot.queryParams;

            let id = this._activatedRoute.snapshot.params["id"];
            if (!id) {
                // try get from query
                id = query["id"] as string;
            }

            if (!id) {
                throw `project id is not passed`;
            }

            // send the new project event
            this._eventService.events.next({ name: "new_project", params: null });

            // load objects first
            const objects = await this._objService.find({ projectId: id });

            // load project
            this.project = await this._projectService.findOne(id);

            this.objects = objects;

            // run the pos initalization process
            this._projectService.postInitalize(this.project, this.objects);

            this.attachToEvents();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public ngOnDestroy(): void {
        try {
            this._eventService.reset();
        }
        catch (err) {
            console.error(err);
        }
    }

    /**
     * Performs verification of the rooftop design by checking the modules, inverters, and optimizers.
     * If any of these objects are missing or deleted, it removes them from the drawing and prompts the user to choose new ones.
     */
    private async verification() {
        try {
            let deletedTypes: string[] = [];

            //
            // check modules
            //
            const segments = this.objects.filter(o => o.type == "segment") as Solar.ObjectRooftopSegment[];

            for (const segment of segments) {
                if (!segment.module) {
                    continue;
                }
                // load module from the db
                const modules = await this._moduleService.find({ id: segment.module._id });
                const module = modules[0];
                if (!module) {
                    if (!deletedTypes.includes("Module")) {
                        deletedTypes.push("Module");
                    }

                    // clear layout
                    this.instance.removeObjects({ ownerId: segment.id, types: ["module"] });
                }
                segment.module = module;
            }

            // 
            // Inverters
            //
            const invObjects = this.objects.filter(o => o.type == "inverter") as Solar.ObjectInverter[];
            for (const invObj of invObjects) {
                const invs = await this._inverterService.find({ id: invObj.inverter._id });
                const inv = invs[0];
                if (!inv) {
                    if (!deletedTypes.includes("Inverter")) {
                        deletedTypes.push("Inverter");
                    }
                    // clear the strings and configuration
                    this.instance.removeObjects({ types: ["string"] });
                    this.project.electrical.stringsConfig = {
                        items: [],
                        isAuto: false
                    };

                    // remove inverer from the drawing
                    this.instance.removeObjects({ id: [invObj.id] })
                }
            }

            // 
            // Optimizers
            //
            const optimizerObjects = this.objects.filter(o => o.type == "optimizer") as Solar.ObjectOptimizer[];
            for (const optimizerObj of optimizerObjects) {
                const optimizers = await this._wsOptimizerService.find({ id: optimizerObj.optimizer._id });
                const optimizer = optimizers[0];
                if (!optimizer) {
                    if (!deletedTypes.includes("Optimizer")) {
                        deletedTypes.push("Optimizer");
                    }
                    // clear the inverters settings
                    for (const invObj of invObjects) {
                        if (invObj.optimizer && invObj.optimizer._id == optimizerObj.optimizer._id) {
                            invObj.optimizer = undefined;
                        }
                    }
                    // remove the optimizer from the drawing
                    this.instance.removeObjects({ id: [optimizerObj.id] })
                }
            }

            // 
            // Show warns
            //
            for (const delType of deletedTypes) {
                await this._dialogService.confirm({
                    title: ``,
                    text: `The '${delType}' you selected has been deleted, please choose a new one`,
                    hideCancel: true
                })
            }
        }
        catch (err) {
            this._notify.error(err);
        }

    }

    /**
     * Callback function triggered when the SolarInstance is ready.
     * @param inst The SolarInstance object.
     */
    public onInstanceReady(inst: SolarInstance) {
        this.instance = inst;

        // activate the automodellin by default
        this._state.isAutoModeling = true;


        // run the project verification
        this.verification();
    }

    /**
     * Callback function that is triggered when the load operation is completed.
     * Sets the isLoading flag to false.
     */
    public onLoadCompleted() {
        this.isLoading = false;
    }

    /**
     * Attaches event listeners to handle various events.
     */
    public attachToEvents() {
        this._eventService.eventsAsObservable.subscribe((opt) => {
            try {
                if (opt.name == "string_changed") {
                    this.detachString(opt.params as Solar.ObjectString);
                }
                else if (opt.name == "segment_edge_changed") {
                    this.cleanUpOnDesignChange(true);
                    // run it on post validation
                    setTimeout(() => {
                        this.onSegmentEdgeChange(opt.params as Solar.ObjectRooftopSegment[]);
                    }, 1);
                } else if (opt.name == "module_dialog_selected") {
                    this.cleanUpOnDesignChange(true);
                }
                else if (opt.name == "tool_completed") {
                    const args = opt.params as { type: Solar.ToolType; };
                    if (args.type == "keepout" ||
                        args.type == "keepout_line" ||
                        args.type == "segment") {
                        this.cleanUpOnDesignChange(true);
                    }
                    else if (args.type == "delete" || args.type == "copy") {
                        this.cleanUpOnDesignChange(false);
                    }
                }
                else if (opt.name == "object_changed") {
                    const changedObject = opt.params as Solar.Object;
                    if (changedObject.type == "module" ||
                        changedObject.type == "keepout" ||
                        changedObject.type == "keepout_line" ||
                        changedObject.type == "segment") {

                        const cleanUpWiring = this.isNeedCleanupWiring(changedObject);

                        this.cleanUpOnDesignChange(cleanUpWiring);
                    }
                }
                else if (opt.name == "object_deleted") {
                    const deletedObject = opt.params as Solar.Object;
                    if (deletedObject.type == "module" ||
                        deletedObject.type == "keepout" ||
                        deletedObject.type == "keepout_line" ||
                        deletedObject.type == "segment") {

                        const cleanUpWiring = this.isNeedCleanupWiring(deletedObject);
                        this.cleanUpOnDesignChange(cleanUpWiring);
                    }

                    if (deletedObject.type == "module") {
                        const module = deletedObject as Solar.ObjectModule;
                        if (module.optimizerId) {
                            // delete optimizer as well
                            this.instance.removeObjects({ id: module.optimizerId });
                        }
                    }

                    if (deletedObject.type == "inverter") {
                        // remove strings that belong to inverters
                        const flatTree = this._wiringService.getFlatTree(this.project.electrical.stringsConfig.items);
                        const invItem = flatTree.find(i => i.object.id == deletedObject.id);
                        if (invItem) {
                            const strsIds = this._wiringService.getFlatTree(invItem.children)
                                .filter(s => s.object.type == "string")
                                .map(s => s.object.id);
                            this.instance.removeObjects({ id: strsIds });
                        }

                        // sync config
                        this._wiringService.sync(this.instance, this.project);
                    }
                }
                if (opt.name == "simulation_legend_ready") {
                    this.legend = opt.params as WebSolarApp.Legend;
                }

                if (opt.name == "project_saved") {
                    this._transactionService.clear();
                }
            }
            catch (err) {
                console.error(err);
            }
        })
    }

    private isNeedCleanupWiring(deletedObject: Solar.Object) {
        let cleanUpWiring = true;
        if (deletedObject.type == "module") {
            cleanUpWiring = false;
        }
        else if (deletedObject.type == "segment") {
            if (!(deletedObject as Solar.ObjectRooftopSegment).module) {
                cleanUpWiring = false;
            }
        }
        return cleanUpWiring;
    }

    /**
     * If the number of modules in the string to which the MPPT has been associated changes, 
     * the string is automatically disconnected from the MPPT.
     * @param str 
     */
    public detachString(str: Solar.ObjectString) {
        const flatTree = this._wiringService.getFlatTree(this.project.electrical.stringsConfig.items);
        const mppts = flatTree.filter(t => t.object.type == "mppt");
        for (const mppt of mppts) {
            const idx = mppt.children.findIndex(c => c.object.id == str.id);
            if (idx >= 0) {
                mppt.children.splice(idx, 1);
            }
        }

        // remove optimizers as well
        const modules = this.instance.getObjects({ id: str.moduleIds }) as Solar.ObjectModule[];
        const optimizersIds = modules.filter(m => m.optimizerId).map(m => m.optimizerId || "");
        this.instance.removeObjects({ id: optimizersIds });
        for (const module of modules) {
            module.optimizerId = "";
        }
    }

    /**
     * Cleans up the project simulation and removes objects from the instance based on the design change.
     * @param clearWiring - A boolean indicating whether to clear the wiring or not.
     */
    private cleanUpOnDesignChange(clearWiring: boolean) {
        // mark the project simulation as not done
        this.project.simulationStatus = "";
        this.instance.removeObjects({ types: ["irradiance"] });

        if (clearWiring) {
            // clear inverters
            this.instance.removeObjects({ types: ["inverter"] });
            this.instance.removeObjects({ types: ["optimizer"] });
            this._wiringService.clearConfig(this.instance, this.project);
        }
    }

    /**
     * Handles the mode change event.
     * Clears the legend and updates the toolbar visibility based on the selected mode.
     */
    public onModeChange() {
        // clear legend
        this.legend = undefined;

        if (this.mode == "Design" ||
            this.mode == "Trees" ||
            this.mode == "Obstacles" ||
            this.mode == "KeepoutLines" ||
            this.mode == "ElectricPole") {
            this.toolbarState.designToolVisible = true;
            this.toolbarState.viewToolsVisible = true;
        }
        else {
            this.toolbarState.designToolVisible = false;
            this.toolbarState.viewToolsVisible = true;
        }

    }

    /**
     * Handles the change event for the rooftop segments.
     * Verifies each segment and performs necessary actions if a segment is invalid.
     * @param segments - An array of rooftop segments.
     */
    private onSegmentEdgeChange(segements: Solar.ObjectRooftopSegment[]) {
        if (!segements || !segements.length) {
            return;
        }

        // verify each segment
        for (const segment of segements) {
            let isValid = this._geometryService.isValidSegment(segment);
            if (!isValid) {
                this._notify.error(`The operation will cause the roof to be abnormal`);

                // restore the last editing
                this._transactionService.undo(this.instance);

                // cancel the edge editor
                this.instance.edgeEditor.disable();
                this.instance.edgeEditor.enable();
                return;
            }
        }
    }
}
