import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SolarInstance, Solar, WebSolarApp, WebSolarEventsService } from '@websolar/ng-websolar';

@Component({
    selector: 'app-rooftop-sidebar',
    templateUrl: './rooftop-sidebar.component.html',
    styleUrls: ['./rooftop-sidebar.component.scss']
})
export class RooftopSidebarComponent {

    @ViewChild("secondPanel") _secondPanel!: ElementRef<HTMLElement>;

    @Input() hideBack = false;

    @Input() instance!: SolarInstance;

    @Input() project!: Solar.Project;

    @Input() mode: WebSolarApp.MenuMode = "Design";

    @Output() modeChange = new EventEmitter<WebSolarApp.MenuMode>();

    public largePanels = [
        "Finance",
        "Battery",
        "Consumption",
        "Electrical",
        "MainServicePanel",
        "Inverters"] as WebSolarApp.MenuMode[];

    public fullWidthPanels = [
        "Mounting",
        "Reports"] as WebSolarApp.MenuMode[];

    constructor(
        private _router: Router,
        private _eventService: WebSolarEventsService) {
    }

    /**
     * Handle menu change
     * @param mode 
     */
    public onMenuItemActivate(mode: WebSolarApp.MenuMode) {
        this.mode = mode;

        if (this.instance) {
            // send complete event if tool active
            this.instance.sendEscape();

            if (mode == "Simulation" || mode == "Reports") {
                this.instance.layers.irradiance = true;
            }
            else {
                this.instance.layers.irradiance = false;
            }

            if (mode == "Electrical" ||
                mode == "Inverters") {
                this.instance.layers.electrical = true;
            }
            else {
                this.instance.layers.electrical = false;
            }

            this.updateEditingByMode();

            this.instance.layers.update();

            setTimeout(() => {
                this.instance.resize();
            }, 10);
        }

        try {
            if (this._secondPanel) {
                // scroll to top
                this._secondPanel.nativeElement.scrollTo({ top: 0 });
            }
        }
        catch (err) {
            console.error(err);
        }

        this.modeChange.emit(this.mode);
    }

    /**
     * Updates the editing mode based on the current mode.
     */
    private updateEditingByMode() {
        if (this.isDesignMode()) {
            this.instance.enableDesignEditing();
            this.instance.objectTracker.setDefaultTypes();
        }
        else if (this.isElectricalMode()) {
            // disable object editor only
            this.instance.disableEditing();
            // enable only object editing for inverters and meters
            this.instance.objectEditor.enable(["inverter", "meter", "main_service_panel", "ac_disconnect", "electrical_pole"]);
            // enable tracking for inverters and strings only
            this.instance.objectTracker.setTrackingTypes(["inverter", "string"]);
        }
        else {
            this.instance.disableEditing();
        }
    }

    /**
     * Checks if the current mode is Electrical.
     * @returns {boolean} True if the mode is Electrical, false otherwise.
     */
    private isElectricalMode() {
        return (
            this.mode == "Electrical" ||
            this.mode == "Inverters" ||
            this.mode == "MainServicePanel" ||
            this.mode == "ACDisconnect" ||
            this.mode == "Meters" ||
            this.mode == "CableTrays"
        );
    }

    /**
     * Checks if the component is in design mode.
     * @returns {boolean} True if the component is in design mode, false otherwise.
     */
    private isDesignMode() {
        return (this.mode == "Design" ||
            this.mode == "Obstacles" ||
            this.mode == "Trees" ||
            this.mode == "KeepoutLines" ||
            this.mode == "ElectricPole");
    }

    /**
     * Navigates to the project list page.
     */
    public onBack() {
        // send the project close event
        this._eventService.events.next({ name: "project_closed", params: null });

        // navigate to the project list
        this._router.navigate(["/project/list"]);
    }

    /**
     * Activates a tool with the specified options.
     * @param opts - The options for the tool.
     * @param opts.type - The type of the tool.
     * @param opts.params - The parameters for the tool.
     */
    public activateTool(opts: { type: Solar.ToolType, params: unknown }) {
        if (!this.instance) {
            console.error(`instance is not initalized`)
            return;
        }
        this.instance.activateTool(opts, this.project);
    }

}
