import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Solar, NotifyService, WebSolarOptimizerService } from '@websolar/ng-websolar';
import { OptimizerEditComponent } from '../optimizer-edit/optimizer-edit.component';
import { CloneTool } from 'src/app/core/clone.tool';

@Component({
    selector: 'app-optimizer-picker-window',
    templateUrl: './optimizer-picker-window.component.html',
    styleUrls: ['./optimizer-picker-window.component.scss']
})
export class OptimizerPickerWindowComponent implements AfterViewInit {

    @ViewChild("paginator") paginator!: MatPaginator;

    public searchText = "";

    public displayedColumns = ['manufacturer', 'model', 'description', 'Pmax', 'Voc', 'efficiency', "range", "copy"];

    public pageOptions = [10, 20, 30];

    public resultsLength = 0;

    public isLoading = true;

    public optimizers: Solar.Optimizer[] = [];

    private _timer: unknown;

    private _loadedOptimizers: Solar.Optimizer[] = [];

    private _inverterId: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            inverterId: string
        },
        private _dialogRef: MatDialogRef<OptimizerPickerWindowComponent>,
        private _optimizerService: WebSolarOptimizerService,
        private _notify: NotifyService,
        private _matDialog: MatDialog,
        private _paginator: MatPaginatorIntl,
        private _translate: TranslateService
    ) {
        this._inverterId = data.inverterId;

        // update paginator lang
        this._paginator.itemsPerPageLabel = this._translate.instant("Items per page");
    }

    ngAfterViewInit(): void {
        this.reload();
    }

    /**
     * Reloads the optimizer picker window by fetching the data from the server.
     * @returns {Promise<void>} A promise that resolves when the reload is complete.
     */
    public async reload() {
        try {
            this.isLoading = true;
            this.optimizers = [];
            this._loadedOptimizers = [];

            await this.loadPage();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    /**
     * Adds a new optimizer.
     * Opens a dialog to edit the optimizer details and saves the changes.
     * If the dialog is closed without saving, no action is taken.
     * After adding the optimizer, the component is reloaded.
     */
    public async add() {
        try {
            const dialogRef = this._matDialog.open(OptimizerEditComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {}
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Edits the optimizer.
     * 
     * @param optimizer - The optimizer to edit.
     * @param evt - The mouse event that triggered the edit.
     * @returns A promise that resolves when the edit is complete.
     */
    public async edit(optimizer: Solar.Inverter, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const dialogRef = this._matDialog.open(OptimizerEditComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    optimizer: optimizer
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Copies the given optimizer and opens a dialog to edit the copied optimizer.
     * 
     * @param optimizer - The optimizer to be copied.
     * @param evt - The mouse event that triggered the copy action.
     * @returns A promise that resolves when the copy operation is completed.
     */
    public async copy(optimizer: Solar.Optimizer, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const newOptimizer = CloneTool.clone(optimizer);
            delete newOptimizer._id;
            newOptimizer.orgId = "";
            newOptimizer.model += ` (${this._translate.instant("Copy")})`

            const dialogRef = this._matDialog.open(OptimizerEditComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    optimizer: newOptimizer
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Executes a search operation.
     * If a timer is already running, it will be cleared and a new timer will be set.
     * After a delay of 300 milliseconds, the paginator's pageIndex will be reset to 0 and the `reload` method will be called.
     */
    public onSearch() {
        if (this._timer) {
            clearTimeout(this._timer as number)
        }

        this._timer = setTimeout(() => {
            this.paginator.pageIndex = 0;
            this.reload();
        }, 300);
    }


    /**
     * Loads the page of optimizers.
     * @returns {Promise<void>} A promise that resolves when the page is loaded.
     */
    public async loadPage() {
        try {
            this.isLoading = true;
            this.optimizers = [];

            const skip = this.paginator.pageIndex * this.paginator.pageSize;

            if (!this._loadedOptimizers.length) {
                this._loadedOptimizers = await this._optimizerService.find({});

                const optimizers: Solar.Optimizer[] = [];
                for (const optimizer of this._loadedOptimizers) {
                    if (!optimizer.invertersIds || !optimizer.invertersIds.length) {
                        continue;
                    }
                    if (optimizer.invertersIds.includes(this._inverterId)) {
                        optimizers.push(optimizer);
                    }
                }

                // apply a filter
                this._loadedOptimizers = optimizers;
            }

            let filteredItems = this._loadedOptimizers.slice(0);
            if (this.searchText) {
                filteredItems = filteredItems.filter(o => (o.model || "").toLowerCase().includes(this.searchText.toLowerCase()));
            }

            this.optimizers = filteredItems.slice(skip, this.paginator.pageSize);
            this.resultsLength = filteredItems.length;
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public onSelect(inverter: Solar.Optimizer) {
        this._dialogRef.close(inverter);

    }

}
