import { Component, ElementRef, ViewChild, AfterViewInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Solar } from '@websolar/ng-websolar';
import { GoogleMapService } from '../../services/google.map.service';
import { ProjectHelper } from '../../core/project.helper';
import { MapProjector } from '../../core/map.projector';

@Component({
    selector: 'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements AfterViewInit, OnChanges {

    @ViewChild("mapElement") _mapElement!: ElementRef<HTMLElement>;

    /**
     * The geographical location data. This is an input property and it's required.
     */
    @Input() location!: Solar.GeoLocation;

    /**
     * The size of the project. This is an input property and it's required.
     */
    @Input() projectSize!: Solar.ProjectSize;

    @Output() locationChange = new EventEmitter<Solar.GeoLocation>();

    private _map!: google.maps.Map;

    private _marker!: google.maps.Marker;

    private _rectangle!: google.maps.Rectangle;

    constructor(
        private _mapService: GoogleMapService
    ) { }

    public ngAfterViewInit(): void {
        this.createMap();
    }

    public ngOnChanges(): void {
        if (this.location) {
            this.updateLocationMarker();
        }
    }

    private updateLocationMarker() {

        if (this._marker) {
            // delete previous marker
            this._marker.setMap(null);
        }

        if (!this.location) {
            return;
        }

        if (!this._map) {
            return;
        }

        const pos = new google.maps.LatLng(this.location.lat, this.location.lng);
        this._map.setCenter(pos);
        this._map.setZoom(ProjectHelper.getZoomLevel(this.projectSize));

        this._marker = new google.maps.Marker({
            position: pos,
            map: this._map,
            title: "Location",
            draggable: false
        });

        this.drawBoundary();
    }


    private onDblClick(evt: google.maps.MapMouseEvent) {
        if (!evt.latLng) {
            return;
        }
        const center = evt.latLng;

        if (!this._marker) {
            this._marker = new google.maps.Marker({
                position: center,
                map: this._map,
                title: "Location",
                draggable: true
            });
        }

        this._marker.setPosition(center);

        this.location.lat = center?.lat() || 0;
        this.location.lng = center?.lng() || 0;

        this.drawBoundary();
    }

    private createMap() {
        this._map = this._mapService.createMap(this._mapElement.nativeElement);

        this._map.addListener("dblclick", this.onDblClick.bind(this))
    }


    private drawBoundary() {
        try {
            if (!this.location ||
                typeof this.location.lat != "number" ||
                typeof this.location.lng != "number") {
                return;
            }
            if (!this._map) {
                return;
            }
            // remove previous
            if (this._rectangle) {
                this._rectangle.setMap(null);
            }

            const zoomLevel = ProjectHelper.getZoomLevel(this.projectSize);
            const imgSize = 640;

            const mapProjector = new MapProjector();
            const boxSize = mapProjector.getSizeInMeters(zoomLevel, this.location, imgSize);
            const halfBox = boxSize;
            const len = Math.sqrt((halfBox * halfBox) + (halfBox * halfBox));

            const center = new google.maps.LatLng(this.location.lat, this.location.lng);
            const sw = google.maps.geometry.spherical.computeOffset(center, -len / 2, 45);
            const ne = google.maps.geometry.spherical.computeOffset(center, len / 2, 45);

            this._rectangle = new google.maps.Rectangle({
                strokeColor: "#FFFFFF",
                strokeWeight: 1,
                fillColor: '#FFFFFF',
                fillOpacity: .2,
                bounds: new google.maps.LatLngBounds(sw, ne),
                map: this._map,
                zIndex: 0
            });
        }
        catch (err) {
            console.error("Failed draw boundary", err);
        }
    }

}
