<div class="component">

    <div class="module-picker"
         *ngIf="project && project.baseConfiguration">
        <app-module-picker [(module)]="project.baseConfiguration.module"
                           (moduleChange)="onModuleChange()"
                           [project]="project"></app-module-picker>
    </div>

    <div class="new-button">
        <button mat-stroked-button
                color="primary"
                (click)="onNewSegment()"
                tourAnchor="new.segment.id"
                id="new.segment.id">
            <mat-icon>add</mat-icon>
            {{'New Roof Segment' | translate}}
        </button>
    </div>

    <div *ngIf="total && segments.length"
         class="total">
        <span>{{'Total' | translate}}</span>
        <mat-icon inline="true">view_module</mat-icon> <span>{{total.count}} {{'modules' | translate}}</span>
        <mat-icon inline="true">bolt</mat-icon> <span>{{total.power}} {{'kWp' | translate}}</span>
        <mat-icon class="hide-sm"
                  inline="true">area_chart</mat-icon>
        <span class="hide-sm">{{total.area | area}}</span>
    </div>

    <div class="list-item"
         *ngFor="let segment of segments">
        <div class="line highlight">
            <div class="title"
                 (click)="toggle(segment)">
                <span class="title-text">
                    {{segment.name || "Segment" | translate}}
                </span>

                <mat-checkbox [disabled]="!project.baseConfiguration.module"
                              [(ngModel)]="segment.isAutoPlacement"
                              (ngModelChange)="onSegmentInstallChange(segment)"
                              color="primary"
                              matTooltip="{{'Installing Solar Panels' | translate}}"
                              (click)="$event.stopPropagation();">
                </mat-checkbox>

                <button class="menu-btn"
                        mat-icon-button
                        (click)="onDeleteSegment(segment)"
                        color="primary">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <div class="info"
                 (click)="toggle(segment)">

                <div class="info-data">
                    <ng-container *ngIf="segment.output">
                        <mat-icon inline="true">view_module</mat-icon> <span>{{segment.output.count}}
                            {{'modules' | translate}}</span>
                        <mat-icon inline="true">bolt</mat-icon> <span>{{segment.output.power}} kWp</span>
                        <mat-icon class="hide-sm"
                                  inline="true">area_chart</mat-icon>
                        <span class="hide-sm">{{segment.output.area | area}}</span>
                    </ng-container>
                </div>


                <div class="expander">
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="segmentState[segment.id]?.expanded">
                        expand_less
                    </mat-icon>
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="!segmentState[segment.id]?.expanded">
                        expand_more
                    </mat-icon>
                </div>
            </div>
        </div>
        <ng-container *ngIf="segmentState[segment.id]?.expanded">
            <app-segment-options [segment]="segment"
                                 [project]="project"
                                 (segmentChange)="onSegmentChange($event)"
                                 (toolActivated)="onToolActivated($event)"></app-segment-options>


            <ng-container *ngIf="segment.module">
                <app-shadows-options [instance]="instance"
                                     [project]="project"
                                     [segment]="segment"></app-shadows-options>
            </ng-container>
        </ng-container>
    </div>

    <div class="hint"
         *ngIf="segments.length == 0">
        {{"You don't have any segments yet. Create new segement." | translate}}
    </div>


</div>
