import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OptimizerPickerWindowComponent } from '../optimizer-picker-window/optimizer-picker-window.component';
import { Solar, NotifyService } from '@websolar/ng-websolar';


@Component({
    selector: 'app-optimizer-picker',
    templateUrl: './optimizer-picker.component.html',
    styleUrls: ['./optimizer-picker.component.scss']
})
export class OptimizerPickerComponent implements OnChanges {

    @Input() optimizer?: Solar.Optimizer | null;

    @Input() project!: Solar.Project;

    /**
     * Filter for optimizers
     */
    @Input() inveterId?: string = "";

    @Output() optimizerChange = new EventEmitter<Solar.Optimizer>();

    public deviceName = "";

    public isLoading = false;

    constructor(
        private _matDialog: MatDialog,
        private _notify: NotifyService
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.optimizer) {
            this.deviceName = this.displayFn(this.optimizer);
        }
        else {
            this.deviceName = "";
        }
    }


    public onChanged() {

    }

    public displayFn(obj: unknown) {
        if (!obj) {
            return "";
        }
        if (typeof obj == "string") {
            return obj;
        }
        const inverter = obj as Solar.Optimizer;
        return `${inverter.model} [${inverter.pmax}W]`;
    }


    public async openSearch(evt: MouseEvent) {

        evt.preventDefault();
        evt.stopPropagation();

        try {
            const dialogRef = this._matDialog.open(OptimizerPickerWindowComponent, {
                autoFocus: true,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    inverterId: this.inveterId || ""
                }
            });
            const res = await dialogRef.afterClosed().toPromise() as Solar.Optimizer;
            if (!res) {
                return;
            }
            this.optimizer = res;
            this.optimizerChange.emit(res);

            this.deviceName = this.displayFn(this.optimizer);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public clear(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();

        this.optimizer = undefined;
        this.optimizerChange.emit(undefined);

        this.deviceName = ""
    }
}
