<div class="component">
    <div class="header">
        <div *ngIf="!module._id">
            {{"Create New Module" | translate}}
        </div>
        <div *ngIf="module._id">
            {{"Edit Module" | translate}}
        </div>
        <button mat-icon-button
                (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="form"
         *ngIf="!isLoading">
        <mat-form-field>
            <mat-label>{{"Manufacturer" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="module.manufacturer">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Model" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="module.model">
        </mat-form-field>


        <mat-form-field>
            <mat-label>{{"Pmax (W)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="module.power">
        </mat-form-field>


        <mat-form-field>
            <mat-label>{{"Long Side (m)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   min="0.01"
                   max="100"
                   [(ngModel)]="module.width">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Short Side (m)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   min="0.01"
                   max="100"
                   [(ngModel)]="module.height">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Thickness (m)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   min="0.01"
                   max="100"
                   [(ngModel)]="module.depth">
        </mat-form-field>

        <!-- <mat-form-field>
            <mat-label>Technology</mat-label>
            <input matInput
                   [(ngModel)]="module.technology">
        </mat-form-field> -->

        <mat-form-field>
            <mat-label>{{"Short-Circuit Current [ISC] (A)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="module.isc">
        </mat-form-field>


        <mat-form-field>
            <mat-label>{{"Weight (kg)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="module.weight">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Open Circuit Voltage [Voc] (V)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="module.voc">
        </mat-form-field>


        <mat-form-field>
            <mat-label>{{ "Maximum Power Voltage [Vmp] (V)" | translate }}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="module.vpmax">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ "Maximum Power Current [Imp] (A)" | translate }}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="module.ipmax">
        </mat-form-field>


        <mat-form-field>
            <mat-label>{{"Temperature Coefficient of Pmax (%/°C)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="module.tpmax">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Temperature Coefficient of Voc (%/°C)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="module.tvoc">
        </mat-form-field>


        <div class="save-btn-wrapper">
            <button (click)="save()"
                    [disabled]="!isCanSave()"
                    mat-flat-button
                    color="primary">
                {{"Save Module" | translate}}
            </button>
        </div>


    </div>
    <div *ngIf="isLoading"
         class="loader-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>
