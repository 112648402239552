<div class="component">
    <div class="title">
        {{"Edit Inverter" | translate}}
    </div>
    <div class="form"
         *ngIf="!isLoading">
        <mat-form-field>
            <mat-label>{{'Description' | translate}}</mat-label>
            <input matInput
                   [placeholder]="'Enter the name' | translate"
                   [(ngModel)]="inverterObj.name">
        </mat-form-field>

        <app-optimizer-picker [optimizer]="optimizer"
                              (optimizerChange)="onOptimizerChange(inverterObj, $event)"
                              [inveterId]="inverterObj.inverter._id"></app-optimizer-picker>
    </div>

    <div *ngIf="isLoading"
         class="loader-wrapper">
        <mat-spinner></mat-spinner>
    </div>

    <div class="btns">
        <button (click)="close()"
                [disabled]="isLoading"
                mat-stroked-button>
            {{"Close" | translate}}
        </button>

        <button (click)="confirm()"
                [disabled]="isLoading"
                mat-flat-button
                color="primary">
            {{"Confirm" | translate}}
        </button>
    </div>
</div>
