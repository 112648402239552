import { NgModule } from '@angular/core';
import { SplitStringPipe } from './pipes/split.string.pipe';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { AppMaterialModule } from './app.material';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconComponent } from './components/icon/icon.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { LogoComponent } from './components/logo/logo.component';
import { BomService } from './services/bom.service';
import {AssetUrlPipe} from "./pipes/asset.url.pipe";
import { LoaderComponent } from './components/loader/loader.component';
import { SolarLegendComponent } from './components/legend/legend.component';
import { RoundPipe } from './pipes/round.pipe';
import { LossesService } from './services/losses.service';
import { CastPipe } from './pipes/cast.pipe';
import { FinanceService } from './services/finance.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    declarations: [
        SplitStringPipe,
        RoundPipe,
        CastPipe,
        InputSearchComponent,
        PaginatorComponent,
        IconComponent,
        LogoComponent,
        LoaderComponent,
        SolarLegendComponent
    ],
    providers: [
        BomService,
        LossesService,
        FinanceService
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        AppMaterialModule,
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AssetUrlPipe
    ],
    exports: [
        SplitStringPipe,
        RoundPipe,
        CastPipe,
        AssetUrlPipe,
        InputSearchComponent,
        PaginatorComponent,
        IconComponent,
        TranslateModule,
        LogoComponent,
        LoaderComponent,
        SolarLegendComponent
    ],
})
export class AppSharedModule { }
