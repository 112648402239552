import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SolarInstance, Solar, WebSolarWiringService, NotifyService } from '@websolar/ng-websolar';
import { Observable, Subject } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
    selector: 'app-main-service-panel-config',
    templateUrl: './main-service-panel-config.component.html',
    styleUrls: ['./main-service-panel-config.component.scss']
})
export class MainServicePanelConfigComponent implements OnChanges {

    @Input() instance!: SolarInstance;

    @Input() project!: Solar.Project;

    @Output() onDelete = new EventEmitter<void>();

    public servicePanels: Solar.StringConfigItem[] = [];

    public actions: { object: Solar.StringConfigItem, name: string }[] = [];

    public events = new Subject<{ name: string, params: unknown }>();

    public eventsAsObservable: Observable<{ name: string, params: unknown }>;

    constructor(
        private _wiringService: WebSolarWiringService,
        private _notify: NotifyService,
        private _dialogService: DialogService
    ) {
        this.eventsAsObservable = this.events.asObservable();

        this.eventsAsObservable.subscribe((opt) => {
            if (opt.name == "refresh") {
                this.refresh();
            }
        })
    }

    /**
     * Lifecycle hook that is called when any data-bound property of the component changes.
     * 
     * @param changes - An object containing the changed properties and their current and previous values.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        try {
            if (!this.project || !this.instance) {
                return;
            }

            this.init();

            this.refresh();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    private init() {
        const config = this.project.electrical.stringsConfig;
        const flatTree = this._wiringService.getFlatTree(config.items);

        const servicePanels = this.instance.getObjects({ types: ["main_service_panel"] }) as Solar.ObjectMainServicePanel[];

        for (const servicePanel of servicePanels) {
            let panelItem = flatTree.find(i => i.object.id == servicePanel.id);
            if (!panelItem) {
                panelItem = {
                    name: "Main Service Panel",
                    object: servicePanel,
                    children: []
                };
                // add the panel to config
                config.items.push(panelItem);
            }
        }
    }


    private refresh() {
        if (!this.project) {
            return;
        }
        if (!this.project.electrical.stringsConfig ||
            !this.project.electrical.stringsConfig.items
        ) {
            // build the default
            this._wiringService.clearConfig(this.instance, this.project);
        }

        // add to actions all unmapped inverters
        //
        this.actions = [];
        const config = this.project.electrical.stringsConfig;

        const flatTree = this._wiringService.getFlatTree(config.items);
        const idsInUse = flatTree.filter(i => i.object).map(i => i.object.id);

        // TODO: Disabled for now
        // Probably will be activted in the next releases
        // this.actions.push({
        //     name: `Combiner Box`,
        //     object: {
        //         name: "Combiner Box",
        //         object: { name: "Combiner Box", type: "combiner_box" } as Solar.ObjectCombinerBox,
        //         children: []
        //     }
        // });

        // this.actions.push({
        //     name: `Junction Box`,
        //     object: {
        //         name: "Junction Box",
        //         object: { name: "Junction Box", type: "junction_box" } as Solar.ObjectJunctionBox,
        //         children: []
        //     }
        // });

        // ac disconnects
        const acDisconnects = this.instance.getObjects({ types: ["ac_disconnect"] }) as Solar.ObjectACDisconnect[];
        for (const disconnect of acDisconnects) {
            if (idsInUse.includes(disconnect.id)) {
                continue;
            }
            this.actions.push({
                name: `${disconnect.name}`,
                object: {
                    name: disconnect.name,
                    object: disconnect,
                    children: []
                }
            });
        }

        for (const topLevelItem of config.items) {
            if (topLevelItem.object.type != "inverter") {
                continue;
            }
            this.actions.push({
                name: `${topLevelItem.name}`,
                object: topLevelItem
            })
        }



        this.servicePanels = this.getPanelItems();
    }

    private getPanelItems(): Solar.StringConfigItem[] {
        // check if a panel inside a config
        const config = this.project.electrical.stringsConfig;
        const flatTree = this._wiringService.getFlatTree(config.items);

        return flatTree.filter(o => o.object.type == "main_service_panel");
    }

    public async deletePanel(item: Solar.StringConfigItem) {

        if (item.object.type != "main_service_panel") {
            return;
        }

        const confirm = await this._dialogService.confirm({
            title: `Delete the main service panel`,
            text: `Are you sure you want to delete this main service panel?`,
            okBtn: "Delete"
        });
        if (!confirm) {
            return;
        }
        // remove children
        this.instance.removeObjects({
            ownerId: item.object.id
        });

        // delete inverter as well
        this.instance.removeObjects({
            id: item.object.id
        });

        // sync the config
        this._wiringService.sync(this.instance, this.project);

        // rebuild
        this.refresh();

        this.onDelete.emit();
    }

    /**
     * Clears the configuration for the main service panel.
     */
    public clearConfig() {
        const panelItems = this.getPanelItems();
        const config = this.project.electrical.stringsConfig;

        for (const item of panelItems) {
            for (let inv of item.children) {
                if (inv.object.type == "inverter") {
                    // move it to root
                    config.items.push(inv);
                }
            }

            item.children = [];
        }
        this.refresh();
    }

    public addComponent(panelItem: Solar.StringConfigItem, obj: Solar.StringConfigItem) {
        // remove from the previous
        const config = this.project.electrical.stringsConfig;
        const idx = config.items.findIndex(i => i.object.id == obj.object.id);
        if (idx >= 0) {
            config.items.splice(idx, 1);
        }

        panelItem.children.push(obj);

        this.refresh();
    }

    public getPanel(item: Solar.StringConfigItem) {
        return (item.object as Solar.ObjectMainServicePanel);
    }
}
